import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import client from '../../../lib/config/client'
import { getToken } from '../../Functions/getToken'

const prefix = 'links'

export const getLinks = createAsyncThunk('links', async ({ page, limit ,filterCreatedBy, buyerName, eventId } , thunkApi) => {
  try {
    const response = await client.get(`${prefix}/get` , {
      params: {
        page,
        limit,
        createdBy: filterCreatedBy == 'All' ? undefined : filterCreatedBy ,
        buyerName,
        eventId : eventId =='All'? undefined : eventId,
      },
      headers: { Authorization: `Bearer ${getToken()?.user}` }
    })

    if (response.data) return response.data
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})
export const getTicketsByLinkId = createAsyncThunk(
  'links/getTickets',
  async (linkId, thunkApi) => {
    try {
      const response = await client.get(`${prefix}/get/${linkId}`, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })

      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue({ error: error.response.data, statusCode: error.response.status })
    }
  }
)
export const getPaymentInfo = createAsyncThunk(
  'links/getPaymentInfo',
  async (linkid, thunkApi) => {
    try {
      const response = await client.get(`${prefix}/payment/${linkid}`, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })

      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const getLink = createAsyncThunk(
  'links/getLink',
  async (linkid, thunkApi) => {
    try {
      const response = await client.get(`${prefix}/${linkid}`, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })

      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const addLink = createAsyncThunk(
  'links/addLink',
  async (data, thunkApi) => {
    try {
      const response = await client.post(`${prefix}/add`, data, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })

      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const addPublicLink = createAsyncThunk(
  'links/addPublicLink',
  async (data, thunkApi) => {
    try {
      const response = await client.post(`${prefix}/add-public-link`, data, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })
      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const createPaymentLink = createAsyncThunk(
  'links/createPaymentLink',
  async (data, thunkApi) => {
    try {
      const response = await client.post(
        `${prefix}/pay/${data.id}`,
        {
          amount: data.amount,
          participants: data.participants
        },
        {
          headers: { Authorization: `Bearer ${getToken()?.user}` }
        }
      )

      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const deleteLink = createAsyncThunk(
  'links/delete',
  async (linkId, thunkApi) => {
    try {
      const response = await client.delete(`${prefix}/delete/${linkId}`, {
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })

      if (response.data) return linkId
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const changeFreePaymmentStatus = createAsyncThunk(
  'links/freepaymentdone',
  async (linkId, thunkApi) => {
    try {
      const response = await client.put(
        `${prefix}/setfree/${linkId}`,
        {},
        {
          headers: { Authorization: `Bearer ${getToken()?.user}` }
        }
      )

      if (response.data) return linkId
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const updateLink = createAsyncThunk(
  'links/update',
  async ({ id, status }, thunkApi) => {
    try {
      const response = await client.put(
        `${prefix}/update/${id}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${getToken()?.user}`
          }
        }
      )
      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const LinksSlice = createSlice({
  name: 'links',
  initialState: {
    links: [],
    link: null,
    tickets: [],
    error: '',
    statusCode:null,
    currentPage: 1,
    totalPages: 0,
    totalLinks: 0,
    success: false,
    paymentLink: null,
    paymentStatus: null,
    loading: false
  },
  reducers: {
    resetLink: (state) => {
      state.links = []
      state.link = null
      state.tickets = []
      state.error = ''
      state.statusCode = null
      state.currentPage=1
      state.totalPages=0
      state.totalLinks=0
      state.success = false
      state.paymentLink = null
      state.paymentStatus = null
      state.loading = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getLinks.fulfilled, (state, action) => {
      state.links = action.payload.links
      state.currentPage=action.payload.currentPage
      state.totalPages=action.payload.totalPages
      state.totalLinks=action.payload.totalLinks
      state.success = true
      state.loading = false
    })
    builder.addCase(getLinks.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getLinks.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })
    builder.addCase(getLink.fulfilled, (state, action) => {
      state.link = action.payload
      state.success = true
    })
    builder.addCase(getLink.rejected, (state, action) => {
      state.error = action.payload
      state.success = false
      state.loading = false
    })
    builder.addCase(createPaymentLink.fulfilled, (state, action) => {
      state.paymentLink = action.payload.payUrl
      state.success = true
    })
    builder.addCase(getPaymentInfo.fulfilled, (state, action) => {
      state.paymentStatus = action.payload.payment.status
      state.success = true
      state.loading = false
    })
    builder.addCase(getPaymentInfo.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getPaymentInfo.rejected, (state, action) => {
      state.loading = false
      state.success = false
    })
    builder.addCase(deleteLink.fulfilled, (state, action) => {
      state.success = true
      state.links = state.links.filter((link) => link._id !== action.payload)
      state.totalLinks -= 1
    })
    builder.addCase(deleteLink.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(updateLink.fulfilled, (state, action) => {
      state.success = true
      state.links = state.links.map((link) =>
        link._id === action.payload._id ? action.payload : link
      )
    })
    builder.addCase(updateLink.rejected, (state, action) => {
      state.error = action.payload
    })

    builder.addCase(addLink.pending, (state, action) => {
      state.loading=true
    })

    builder.addCase(addLink.fulfilled, (state, action) => {
      state.loading=false
      state.success = true
      state.links.push(action.payload)
      state.links.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
      state.totalLinks += 1
    })
    builder.addCase(addLink.rejected, (state, action) => {
      state.loading=false
      state.error = action.payload
    })
    builder.addCase(changeFreePaymmentStatus.fulfilled, (state, action) => {
      state.success = true
      state.links = state.links.map((link) =>
        link._id === action.payload._id ? action.payload : link
      )
    })
    builder.addCase(changeFreePaymmentStatus.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(getTicketsByLinkId.fulfilled, (state, action) => {
      state.success = true
      state.tickets = action.payload
      state.error = ''
      state.statusCode = null
    })
    builder.addCase(getTicketsByLinkId.rejected, (state, action) => {
      state.error = action.payload.error
      state.statusCode = action.payload.statusCode
    })
    builder.addCase(addPublicLink.pending, (state, action) => {
      state.loading=true
    })

    builder.addCase(addPublicLink.fulfilled, (state, action) => {
      state.loading=false
      state.success = true
      state.link= action.payload
    })
    builder.addCase(addPublicLink.rejected, (state, action) => {
      state.loading=false
      state.error = action.payload
    })
  }
})

export const { resetLink } = LinksSlice.actions
export default LinksSlice.reducer
