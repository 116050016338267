import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EventCard from '../components/Core/Cards/EventCard'
import LoadingSpinner from '../components/Layout/LoadingSpinner'
import { getEventsAffected } from '../services/Redux/slices/EventsSlice'
import undrawTaken from '../assets/images/undrawTaken.png'

const CollaborativeSales = () => {

  const dispatch = useDispatch()

  const { eventsAffected, loading } = useSelector((state) => state.events)

  const [eventName, setEventName] = useState();
  const [eventDate, setEventDate] = useState();


  useEffect(() => {
    dispatch(getEventsAffected({ eventName, eventDate }))
  }, [dispatch, eventName, eventDate])

  return (
    <div className='container mx-auto'>
      <div className=' flex items-center justify-between mb-4'>
        <p className='text-2xl font-medium '>Collaborative Sales</p>
      </div>
      <div className=' border-b  border-primaryColorLight pb-1 mb-4'>
        <div className='flex flex-col md:flex-row gap-4'>
          <div className='flex flex-col w-full md:w-52'>
            <label className='text-gray-500 text-sm'>
              Filter By <span className='text-primaryColorLight'> Event Name </span>
            </label>
            <input
              type='text'
              placeholder='Event Name'
              className='p-2 border rounded-sm mb-1 w-full md:w-52 h-9'
              onChange={(e) => setEventName(e.target.value)}
            />
          </div>
          <div className='flex flex-col w-full md:w-52'>
            <label className='text-gray-500 text-sm'>
              Filter By <span className='text-primaryColorLight'> Event Date </span>
            </label>
            <input
              type='date'
              placeholder='Participant Name'
              className='p-2 border rounded-sm mb-1 w-full md:w-52 h-9'
              onChange={(e) => setEventDate(e.target.value)}
            />
          </div>
        </div>
      </div>
      {
        loading ?
          <LoadingSpinner />
          :
          eventsAffected?.length === 0 ?
            <div className="flex flex-col items-center justify-center">
              <div className="">
                <div className="text-center">
                  <div className="">
                    <img src={undrawTaken} alt='time out' width={550} />
                  </div>
                  <p className="text-2xl font-semibold">Oops… No collaborations yet!</p>
                  <p className="text-secondary text-gray-600 mt-2">
                    It looks like you don't have any collaborative sales for your friends' events at the moment.
                  </p>

                </div>
              </div>
            </div>
            :
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
              {eventsAffected?.map((event) => (
                <EventCard
                  key={event?._id}
                  event={event}
                  collaboration={true}
                />
              ))}


            </div>
      }
    </div>
  )
}

export default CollaborativeSales