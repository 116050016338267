
import * as z from 'zod';

export const eventSchema = z.object({
    name: z.string().min(1, 'Event name is required'),
    dateStart: z.string().min(1, 'Start date is required'),
    dateEnd: z.string().min(1, 'End date is required'),
    team: z.string().optional(),
    ticketsType: z.array(z.string()).min(0, 'Ticket type should be an array of strings').optional(),
    publicUrl: z.boolean(),
    address: z.string().optional(),
    venue: z.string().min(1, 'Venue is required'),
    rue: z.string().min(1, 'Street is required'),
    ville: z.string().min(1, 'City is required'),
    region: z.string().min(1, 'Region is required'),
    pays: z.string().min(1, 'Country is required'),
    codePostale: z.string().min(1, 'Postal code is required'),
    promoter: z.string().min(1, 'Promoter is required'),
    codePhone: z.string().min(1, 'Promoter Phone is required'),
    promoterPhone: z.string().min(1, 'Promoter Phone is required'),
    image: z.string().min(1, 'Event Cover is required'),
}).superRefine((data, ctx) => {
    const now = new Date();
    const dateStart = new Date(data.dateStart);
    const dateEnd = new Date(data.dateEnd);

    // Check if dateStart is >= current date and time
    if (dateStart < now) {
        ctx.addIssue({
            path: ['dateStart'],
            message: 'Start date must be equal to or greater than the current date and time',
        });
    }

    // Check if dateEnd is >= current date and time
    if (dateEnd < now) {
        ctx.addIssue({
            path: ['dateEnd'],
            message: 'End date must be equal to or greater than the current date and time',
        });
    }

    // Check if dateEnd is >= dateStart
    if (dateEnd < dateStart) {
        ctx.addIssue({
            path: ['dateEnd'],
            message: 'End date must be equal to or greater than the start date',
        });
    }
});;