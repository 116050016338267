import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import clsx from 'clsx'
import navMenuLinksData from './SideBar/navMenuLinks'
import NavMenuLinksWrapper from './SideBar/navMenuLinksWrapper'
import SidebarLink from './SideBar/SideBarLink'
import { useSelector } from 'react-redux'

function MobileSidebar(props) {
  const { open ,  onToggleMobileSideBar} = props
  const [activePage, setActivePage] = useState('')
  
  useEffect(() => {
    const pathname = window.location.pathname
    setActivePage(pathname)
  }, [activePage])

  const { user } = useSelector((state) => state.auth); 
  const filteredMenuLinks = navMenuLinksData.filter((item) =>
    item.roles.includes(user.role)
  );

  return (
    <nav
      className={clsx(
        'absolute left-0 top-0 z-50 w-full bg-primaryColor transition-transform',
        open ? 'translate-y-0' : '-translate-y-full'
      )}
    >
      {filteredMenuLinks.map((navLink) => {
        return (
          <NavMenuLinksWrapper
            tooltipLabel={navLink.label}
            hideTooltip={open}
            key={navLink.href}
          >
            <SidebarLink
              isActive={activePage === navLink.href}
              icon={navLink.icon}
              label={navLink.label}
              href={navLink.href}
              setActivePage={() => {}}
              open={open}
              onToggleMobileSideBar={onToggleMobileSideBar}
            />
          </NavMenuLinksWrapper>
        )
      })}
    </nav>
  )
}

export default MobileSidebar
