import * as z from 'zod'


export const teamSchema = z.object({
    teamColor: z
      .string({
        required_error: 'Team color is required'
      })
      .trim(),
    teamName: z
      .string({
        required_error: 'Team Name is required'
      })
      .trim()
      .min(1, { message: 'Team Name is required' }),
    sellers: z
      .array(
        z
          .object({
            value: z.string() // Expect the `value` property as a string
          })
          .transform((obj) => {
            return obj.value
          })
      )
      .default([]),
    controllers: z
      .array(
        z
          .object({
            value: z.string() // Expect the `value` property as a string
          })
          .transform((obj) => {
            return obj.value
          })
      )
      .default([])
  })