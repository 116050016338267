import { useState, useEffect } from 'react';

const CountdownTimer = ({ createdAt }) => {
  const [timeLeft, setTimeLeft] = useState(() => {
    const createdTime = new Date(createdAt).getTime();
    const currentTime = Date.now();
    const elapsedSeconds = Math.floor((currentTime - createdTime) / 1000);
    // Total duration: 20 minutes in seconds
    const totalDuration = 1200;
    // Calculate remaining time, ensure it's not negative
    return Math.max(0, totalDuration - elapsedSeconds);
  });

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 1) {
          // Clear interval and refresh page when timer reaches 0
          clearInterval(timer);
          window.location.reload();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Convert seconds to MM:SS format
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

  return (
    <p>
      This page will expire in{' '}
      <span className="text-primaryColorLight font-bold">{formattedTime}</span>
    </p>
  );
};

export default CountdownTimer;