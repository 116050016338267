import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import client from '../../../lib/config/client'
import { getToken } from '../../Functions/getToken'

const prefix = 'users'

export const getUsers = createAsyncThunk(
  prefix,
  async ({ page, limit, query }, thunkApi) => {
    try {
      const response = await client.get(`/get-users`, {
        params: {
          page,
          limit,
          query
        },
        headers: { Authorization: `Bearer ${getToken()?.user}` }
      })
      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)

export const UsersSlice = createSlice({
  name: prefix,
  initialState: {
    users: [],
    error: null,
    loading: false
  },
  reducers: {
    resetUsers: (state) => {
      state.error = null
      state.users = []
      state.loading = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.users = payload
      state.loading = false
    })
    builder.addCase(getUsers.rejected, (state, { payload }) => {
      state.loading = false
      state.error = payload
      state.users = []
    })
  }
})

export const { resetUsers } = UsersSlice.actions
export default UsersSlice.reducer
