import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { confirmPassword } from '../../../services/Redux/slices/authSlice';
import { XIcon , Eye, EyeOff} from 'lucide-react'


const PasswordModal = ({ isOpen, onClose, onConfirm, toDeleteEvent = false }) => {
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState('');
  const dispatch = useDispatch();

  const handleConfirm = () => {
    dispatch(confirmPassword({ password }))
      .then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          onConfirm();
        } else {
          setError('Incorrect password');
        }
      })
      .finally(() => {
        setPassword('');
      });
  };

  if (!isOpen) {
    return null;
  }
  const handleClose = () => {
    setError('');
    onClose();
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="fixed -inset-0 z-50 bg-gray-600 bg-opacity-60 flex justify-center items-center p-4">
      <div className="bg-white rounded-sm shadow-xl max-w-md w-full" role="dialog" aria-modal="true" aria-labelledby="modal-title">
        <div className="p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 id="modal-title" className="text-xl font-bold text-gray-900">Warning</h2>
            <button
              onClick={handleClose}
              className="text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              <XIcon className="h-6 w-6" />
              <span className="sr-only">Close</span>
            </button>
          </div>
          {toDeleteEvent &&
            <div className="mb-4">
              <p className="text-sm text-gray-500">
              This action is irreversible. Deleting this event will permanently remove all associated <span className='text-primaryColor font-bold'> tickets </span> and  <span className='text-primaryColor font-bold'> links </span>. You can download the ticket reports for this event before proceeding.
              </p>
            </div>
          }
          <div className="mb-4">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
              Confirm Password
            </label>
            <div className="relative">
              <input
                type={passwordVisible ? 'text' : 'password'} 
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-primaryColor focus:border-primaryColor"
                placeholder="Enter your password"
              />
              <button
                type="button"
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-500"
              >
                {passwordVisible ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
              </button>
            </div>
          </div>
          {error && (
            <p className="text-red-500 text-sm mb-4" role="alert">
              {error}
            </p>
          )}
          <div className="flex justify-end space-x-3">
            <button
              onClick={handleClose}
              className="px-4 py-2 border border-gray-300 rounded-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
            >
              Cancel
            </button>
            <button
              onClick={handleConfirm}
              className="px-4 py-2 border border-transparent rounded-sm shadow-sm text-sm font-medium text-white bg-primaryColor "
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
