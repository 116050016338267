import React from 'react';

const TicketInfo = ({ link }) => (
  <div className='mb-8'>
    <div className='grid grid-cols-1 gap-6 md:grid-cols-3'>
      <div>
        <label className='block text-sm font-medium text-gray-700'>
          Ticket Type
        </label>
        <input
          type='text'
          value={link?.ticketType?.name}
          disabled
          className='mt-1 block w-full px-3 py-2 h-10 bg-gray-100 border border-gray-300 rounded-sm shadow-sm'
        />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>
          Price
        </label>
        <input
          type='text'
          value={`${link?.ticketType?.price} TND`}
          disabled
          className='mt-1 block w-full px-3 py-2 h-10 bg-gray-100 border border-gray-300 rounded-sm shadow-sm'
        />
      </div>
      <div>
        <label className='block text-sm font-medium text-gray-700'>
          Ticket Max Quantity
        </label>
        <input
          type='text'
          value={link?.nbTicketMax}
          disabled
          className='mt-1 block w-full px-3 py-2 h-10 bg-gray-100 border border-gray-300 rounded-sm shadow-sm'
        />
      </div>
    </div>
  </div>
);

export default TicketInfo;
