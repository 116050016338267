import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import TicketsList from '../components/Core/Links&Tickets/TicketsList'
import { getLink } from '../services/Redux/slices/LinksSlice';
import { downloadTicketPdf, getMyOwnTickets } from '../services/Redux/slices/tickets';
import { useParams } from 'react-router-dom';
import Error from './Error';

const MyOwnTickets = () => {

    const dispatch = useDispatch();
    const { linkId, phoneNumber } = useParams();
    const { link } = useSelector((state) => state.links);
    const { myOwnTickets, error, statusCode } = useSelector((state) => state.tickets)

    useEffect(() => {
        dispatch(getLink(linkId));
        dispatch(getMyOwnTickets({ linkId, phoneNumber }))
    }, [dispatch, linkId, phoneNumber]);

    const handleClick = (id) => {
        dispatch(downloadTicketPdf(id));
    };

    if (error) {
        return <Error statusCode={statusCode} />;
    }

    return (
        <div className='bg-gray-100'>
            <div>
                <>
                    <TicketsList tickets={myOwnTickets} handleClick={handleClick} link={link} />
                </>
            </div>


        </div>
    )
}

export default MyOwnTickets