import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import Dashboard from './pages/Dashboard'
import Login from './pages/login'
import ProtectedRoute from './utils/ProtectedRoute'
import TicketTypes from './pages/TicketTypes'
import Links from './pages/Links'
import Tickets from './pages/tickets'
import GeneratedLink from './pages/GeneratedLink'
import Logout from './pages/logout'
import Team from './pages/Team'
import { Roles } from './enum'
import Home from './components/Core/Dashboard/Home'
import GeneratePublicLink from './pages/GeneratePublicLink'
import Notification from './components/Core/Notification/Notification'
import CollaborativeSales from './pages/CollaborativeSales'
import MyEventSales from './pages/MyEventSales'
import Error from './pages/Error'
import MyOwnTickets from './pages/MyOwnTickets'

const App = () => {
  return (
    <>
      <Notification />
      <Routes>
        <Route path='/ticket/:id' element={<GeneratedLink />} />
        <Route path='/event/:id' element={<GeneratePublicLink />} />
        <Route path='/ticket/:linkId/:ticketId' element={<MyOwnTickets />} />
        <Route
          path='/dashboard'
          element={
            <ProtectedRoute roles={[Roles.USER]}>
              <Dashboard />
            </ProtectedRoute>
          }
        >
          {/* Routes accessible by user */}
          {/* <Route path='' element={<QRCodeScanner />} /> */}
          <Route path='' element={<Home />} />
          <Route path='ticketTypes' element={<ProtectedRoute><TicketTypes /></ProtectedRoute>} />
          <Route path='collaborative-sales' element={<ProtectedRoute><CollaborativeSales /></ProtectedRoute>} />
          <Route path='my-event-sales' element={<ProtectedRoute><MyEventSales /></ProtectedRoute>} />
          <Route path='links' element={<ProtectedRoute><Links /></ProtectedRoute>} />
          <Route path='tickets' element={<ProtectedRoute><Tickets /></ProtectedRoute>} />
          <Route path='tickets/:id' element={<ProtectedRoute><Tickets /></ProtectedRoute>} />
          <Route path='team' element={<ProtectedRoute><Team /></ProtectedRoute>} />
          <Route path='*' element={<ProtectedRoute><Error/></ProtectedRoute>} />
          {/* Common route */}
          <Route path='logout' element={<Logout />} />
        </Route>
        <Route path='' element={<Login />} />
        <Route path='login' element={<Login />} />

        
      </Routes>

    </>

  )
}

export default App
