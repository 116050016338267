import React from 'react';
import ParticipantForm from './ParticipantForm';

const ParticipantsList = ({ participants, participantsCount, handleParticipantChange, removeParticipant, addParticipant, exceededError, validationError }) => (

    <div className='my-2'>
        <h2 className='text-xl font-semibold mb-2 text-gray-800'>
            Participant Information
        </h2>
        {participants.map((participant, index) => (
            <ParticipantForm
                key={index}
                participant={participant}
                index={index}
                onChange={handleParticipantChange}
                onRemove={removeParticipant}
            />
        ))}
        {!exceededError && (
            <button
                type='button'
                onClick={addParticipant}
                className={`inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-sm shadow-sm text-white ${participantsCount == 1 || participants.length == participantsCount
                        ? 'bg-gray-400 cursor-not-allowed'
                        : 'bg-green-600 hover:bg-green-700'
                    }`}
                disabled={participantsCount == 1 || participants.length == participantsCount}
            >
                Add Participant
            </button>
        )}
        {exceededError && (
            <div className='text-red-600 text-sm mt-2'>
                {exceededError}
            </div>
        )}
        {validationError && (
            <div className='text-red-600 text-sm mt-2'>
                {validationError}
            </div>
        )}
    </div>
);

export default ParticipantsList;
