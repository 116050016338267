import { configureStore } from '@reduxjs/toolkit'
import LinksReducer from './slices/LinksSlice'
import eventsReducer from './slices/EventsSlice'
import TicketTypesReducer from './slices/TicketTypes'
import ticketsReducer from './slices/tickets'
import authReducer from './slices/authSlice'
import teamsReducer from './slices/TeamSlice'
import UsersSlice from './slices/UsersSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    teams: teamsReducer,
    events: eventsReducer,
    types: TicketTypesReducer,
    tickets: ticketsReducer,
    links: LinksReducer,
    users: UsersSlice
  }
})
