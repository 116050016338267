import React, { useState } from 'react'
import { LinkIcon, Trash2Icon, Edit3Icon, InfoIcon, CalendarIcon, MoreVerticalIcon, TicketIcon } from "lucide-react"
import AddLink from '../Modals/Add/AddLink';
import EventDetails from '../Modals/Info/EventDetails';
import PasswordModal from '../../Layout/DataTable/passwordModel';
import { useDispatch } from 'react-redux';
import { deleteEvent } from '../../../services/Redux/slices/EventsSlice';
import EditEvent from '../Modals/Edit/EditEvent'
import { Link } from 'react-router-dom'


export default function EventCard({ event, collaboration = false }) {

    const dispatch = useDispatch()

    const [isOpen, setIsOpen] = useState(false)
    const [showOptions, setShowOptions] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [ToDelete, setToDelete] = useState(null);

    const [toEditModal, setToEditModal] = useState(false)

    const openModal = () => setIsOpen(!isOpen)
    const toggleEditModal = () => setToEditModal(!toEditModal)
    const toggleDetailsModal = () => setShowDetails(!showDetails)
    const toggleOptions = () => setShowOptions(!showOptions)

    const SDate = new Date(event?.dateStart)
    const eDate = new Date(event?.dateEnd)

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    }

    const startDate = SDate.toLocaleString('en-US', options)
    const endDate = eDate.toLocaleString('en-US', options)

    const isEventEnded = () => {
        if (!event?.dateEnd) return false
        const eventEndDate = new Date(event.dateEnd)
        const currentDate = new Date()
        const isEnded = eventEndDate < currentDate
        return isEnded
    }

    const isEventSoldOut = () => {
        const isSoldOut = event?.ticketsType?.length === 0
        return isSoldOut
    }

    const isEventUnavailable = () => {
        const unavailable = isEventEnded() || isEventSoldOut()
        return unavailable
    }

    const handleDeleteClick = () => {
        setIsPasswordModalOpen(true);
    };

    const handlePasswordConfirm = () => {
        dispatch(deleteEvent(event?._id))
        setIsPasswordModalOpen(false);
    };

    const ButtonsGroup = () => (
        <div className={`grid ${collaboration ? 'grid-cols-1' : 'grid-cols-2'} gap-2`}>
            <button
                className="w-full rounded-sm flex items-center z-50 justify-center py-2 px-4 bg-white/100 text-black cursor-pointer"
                onClick={toggleDetailsModal}
            >
                <InfoIcon className="w-4 h-4 mr-2" />
                More Details
            </button>

            {!collaboration && (
                <>
                    <button
                        variant="outline"
                        className="w-full rounded-sm flex z-50 items-center justify-center py-2 px-4 bg-white/100 text-black cursor-pointer"
                        onClick={toggleEditModal}
                    >

                        <Edit3Icon className="w-4 h-4 mr-2" />
                        Edit Event
                    </button>

                    <button
                        className="rounded-sm flex z-50 items-center justify-center py-2 px-4 bg-white/100 text-black cursor-pointer"
                    >
                        <Link to={`/dashboard/tickets/${event?._id}`}>
                            <div className='flex z-50 items-center justify-center'>
                                <TicketIcon className="w-4 h-4 mr-2" />
                                Tickets Sales
                            </div>
                        </Link>
                    </button>

                    <button
                        variant="destructive"
                        className="w-full rounded-sm flex z-50 items-center justify-center py-2 px-4 bg-white/100 text-black cursor-pointer"
                        onClick={handleDeleteClick}
                    >
                        <Trash2Icon className="w-4 h-4 mr-2" />
                        Delete Event
                    </button>
                </>
            )}
        </div>
    )

    return (
        <div className="relative overflow-hidden h-[400px] group rounded-sm">
            <div className="absolute inset-0">
                <img
                    src={event?.image}
                    alt={event?.name}
                    className={`w-full h-full object-cover ${isEventUnavailable() ? 'filter grayscale' : ''}`}
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/30 " />
            </div>

            <div className="relative h-full flex flex-col justify-between p-4 z-10">

                <div className="self-end bg-white/75 p-2 rounded-sm shadow-md flex flex-col items-center gap-0">
                    <CalendarIcon className="w-5 h-5" />
                    <span className="text-md font-semibold text-primary m-0 p-0">{SDate.getDate()}</span>
                    <div className="flex gap-1">
                        <span className="text-sm text-primary m-0 p-0">{SDate.toLocaleString('default', { month: 'short' })}</span>
                        <span className="text-sm text-primary m-0 p-0">{SDate.getFullYear()}</span>
                    </div>
                </div>

                {isEventSoldOut() && !isEventEnded() && (
                    <div className="absolute inset-0 sm:mb-24 flex items-center justify-center">
                        <div className="bg-red-600 bg-opacity-55 text-white text-5xl font-bold p-3 -rotate-12 transform origin-center">
                            SOLD OUT
                        </div>
                    </div>
                )}
                {isEventEnded() && (
                    <div className="absolute inset-0 sm:mb-24 flex items-center justify-center sm:px-5">
                        <div className="bg-green-600 bg-opacity-55 text-white text-5xl font-bold p-3 -rotate-12 transform origin-center">
                            COMPLETED
                        </div>
                    </div>
                )}
                <div className="mt-auto">
                    <h2 className="text-2xl font-semibold text-white truncate mb-4">{event?.name}</h2>

                    <div className=" relative grid grid-cols-2 gap-2">
                        <button
                            onClick={toggleOptions}
                            className="w-full rounded-sm flex items-center justify-center py-2 px-4 bg-white/80 text-black"
                        >
                            <MoreVerticalIcon className="w-4 h-4 mr-2" />
                            Options
                        </button>

                        <button
                            className={`w-full rounded-sm flex items-center z-50 text-primaryColor justify-center py-2 px-4 ${isEventUnavailable() ? 'bg-gray-400 text-white cursor-not-allowed' : 'bg-white/100'
                                }`}
                            disabled={isEventUnavailable()}
                            onClick={openModal}
                        >
                            <LinkIcon className="w-4 h-4 mr-2" />
                            Generate Link
                        </button>

                        {showOptions && (
                            <div className="absolute bottom-full left-0 right-0 mb-2 grid grid-cols-1 gap-2 bg-white/80 p-2 rounded-sm">
                                <ButtonsGroup />
                            </div>
                        )}
                    </div>

                </div>
            </div>

            <AddLink isOpen={isOpen} openModal={openModal} event={event} />
            <EventDetails isOpen={showDetails} toggleDetailsModal={toggleDetailsModal} event={event} />
            <EditEvent isOpen={toEditModal} openModal={toggleEditModal} eventData={event} />

            <PasswordModal
                isOpen={isPasswordModalOpen}
                onClose={() => setIsPasswordModalOpen(false)}
                onConfirm={handlePasswordConfirm}
                toDeleteEvent={true}
            />
        </div>
    )
}