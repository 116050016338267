import React from 'react';

const ParticipantForm = ({ participant, index, onChange, onRemove }) => (
  <div className='grid grid-cols-1 gap-6 md:grid-cols-3 items-center mb-4'>
    <div>
      <label className='block text-sm font-medium text-gray-700'>
        Participant Name
      </label>
      <input
        type='text'
        value={participant.name}
        onChange={(e) => onChange(index, 'name', e.target.value)}
        className='mt-1 block w-full px-3 py-2 h-10 bg-white border border-gray-300 rounded-sm shadow-sm'
      />
    </div>
    <div>
      <label className='block text-sm font-medium text-gray-700'>
        Phone Number
      </label>
      <input
        type='text'
        value={participant.phone}
        onChange={(e) => onChange(index, 'phone', e.target.value)}
        className='mt-1 block w-full px-3 py-2 h-10 bg-white border border-gray-300 rounded-sm shadow-sm '
      />
    </div>
    {index>0 && <button
      type='button'
      onClick={() => onRemove(index)}
      className='mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-sm shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
    >
      Remove
    </button>}
  </div>
);

export default ParticipantForm;