import React from 'react';
import Select from 'react-select';
import { Trash2 } from 'lucide-react';
import { countries } from '../../../data/countries';

const ParticipantForm = ({ participant, index, onChange, onRemove }) => {
  const countryOptions = countries.map(({ dial_code, emoji, i }) => ({
    value: dial_code,
    label: `${emoji} | ${dial_code}`
  }));

  const handleCountryCodeChange = (selectedOption) => {
    onChange(index, 'pCodePhone', selectedOption ? selectedOption.value : '');
  };

  return (
    <div className='grid grid-cols-1 gap-6 md:grid-cols-6 items-center mb-4'>
      <div className='md:col-span-2'>
        <label className='block text-sm font-medium text-gray-700'>
          {index > 0 ? `Participant Name (${index})` : 'Participant Name'}
        </label>
        <input
          type='text'
          value={participant.name}
          onChange={(e) => onChange(index, 'name', e.target.value)}
          className='mt-1 block w-full px-3 py-2 h-10 bg-white border border-gray-300 rounded-none focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors'
          placeholder='Participant Name'
        />
      </div>

      <div className='md:col-span-3'>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {index > 0 ? `Participant Phone Number (${index})` : 'Participant Phone Number'}
        </label>
        <div className="flex">
          <div className="flex-grow me-1">
            <Select
              options={countryOptions}
              value={countryOptions.find(option => option.value === participant.pCodePhone) || null}
              onChange={handleCountryCodeChange}
              placeholder="Select"
              styles={{
                control: (base, state) => ({
                  ...base,
                  width: '9rem',
                  height: "40px",
                  borderRadius: '0px',
                  boxShadow: 'none',
                  borderColor:'#E0E3E7',
                  '&:focus-within': {
                      borderColor: '#D73556',
                      boxShadow: state.isFocused ? '0 0 0 1px #D73556' : 'none',
                  }
              }),
              }}
            />
          </div>

          <input
            type="tel"
            placeholder="Enter phone number"
            className="block w-full md:w-52 pl-4 h-10 border border-gray-300 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
            value={participant.phone}
            onChange={(e) => onChange(index, 'phone', e.target.value)}
          />
        </div>
      </div>

      <div className="md:col-span-1">
        {index > 0 && (
          <button
            type='button'
            onClick={() => onRemove(index)}
            className='md:mt-6 w-full inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm font-medium rounded-sm shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
          >
            <Trash2 className='me-1' />
            Remove
          </button>
        )}
      </div>
    </div>
  );
};

export default ParticipantForm;