import React from 'react';
import { CreditCard } from "lucide-react"

const PaymentButton = ({ paymentLink }) => {
  const handlePaymentClick = () => {
    window.location.href = paymentLink;
  };

  return (
    <button 
      className="bg-primaryColor hover:bg-primaryColor text-white font-medium py-2 px-4 rounded-sm transition duration-300 ease-in-out flex items-center justify-center w-full sm:w-auto"
      onClick={handlePaymentClick}  
    >
      <CreditCard className="mr-2 h-5 w-5" />
      Proceed to Payment
    </button>
  );
};

export default PaymentButton;
