import React, { useEffect, useRef, useState } from 'react';
import { ArrowDownToLine, RefreshCw, User, Phone } from 'lucide-react'
import mcTickets from '../../../assets/images/mcp.svg'
import AppStorePopup from '../PpoUp/AppStorePopup';
import { useDispatch } from 'react-redux';
import { sendTicketsForParticipant } from '../../../services/Redux/slices/tickets';
import { triggerNotification } from '../Notification/Notification';


const TicketsList = ({ tickets, handleClick, link }) => {

  const dispatch = useDispatch();
  const dispatchedTickets = useRef(new Set());
  const [isLoading, setIsLoading] = useState(false)

  const reloadPage = () => {
    setIsLoading(true)
    window.location.reload();
    setIsLoading(false)
  };

  // Calculate grid columns based on number of tickets
  const getGridClass = (ticketCount) => {
    if (ticketCount === 1) {
      return 'grid-cols-1 max-w-md mx-auto'; // Single centered column with max width
    } else if (ticketCount === 2) {
      return 'md:grid-cols-2 max-w-2xl mx-auto'; // Two centered columns with max width
    }
    return 'md:grid-cols-2 lg:grid-cols-3'; // Default grid for 3 or more tickets
  };


  useEffect(() => {
    tickets?.forEach(ticket => {
      if (ticket.smsSent == false  && ticket?.pushNotifSent == false  && !dispatchedTickets.current.has(ticket._id)) {
        dispatch(sendTicketsForParticipant(ticket._id));
        dispatchedTickets.current.add(ticket._id);
        triggerNotification(
          "SMS Sent Successfully!",
          "An SMS with a ticket link has been successfully sent to each participant.",
          "success",
          10000
        );
      }
    });
  }, [tickets, sendTicketsForParticipant]);

  return (
    <div className="min-h-screen bg-white p-8">
      <div className="max-w-6xl mx-auto">
        <header className="flex justify-center items-center mb-12">
          <div className="flex items-center gap-4">
            <img
              src={mcTickets}
              width={250}
              alt="Company Logo"
            />
          </div>
        </header>
        <h1 className="text-4xl text-center font-bold tracking-tight">Your <span className='text-primaryColor'>tickets </span> are ready! Can’t wait to see you at the event!</h1>
        
        <p className="text-gray-600 mt-4 text-center">
          Don't see your ticket? Click here to{' '}
          <button 
            onClick={reloadPage}
            className="text-primaryColor hover:text-[#d73757] inline-flex items-center gap-1 font-medium"
            disabled={isLoading}
          >
            reload
            <RefreshCw className={`w-4 h-4 ${isLoading ? 'animate-spin' : ''}`} />
          </button>
        </p>
        
        
        <div className={`grid grid-cols-1 gap-8 my-10 ${getGridClass(tickets?.length)}`}>
          {tickets && tickets.length > 0 && tickets?.map((ticket,i) => (
            <div key={i} className="bg-white/5 backdrop-blur-lg rounded-sm overflow-hidden shadow-md border border-gray-200 hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2">
              <div className="relative">
                <img src={link?.event?.image} alt={link?.event?.name} className="w-full h-32 object-cover opacity-55" />
                <div className="absolute inset-0 flex flex-col items-center justify-center text-center p-4">
                  <h3 className="text-2xl text-gray-900 font-semibold">{link?.event?.name}</h3>
                  <p className="text-base text-gray-900 mt-1">#{link?.ticketType?.name}</p>
                </div>
              </div>
              <div className="p-4 space-y-2">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center gap-2">
                    <User className="w-4 h-4 text-gray-500" />
                    <span className="text-xs text-gray-500">Participant:</span>
                    <span className="font-medium text-sm">{ticket?.participant}</span>
                  </div>
                  <div className="flex items-center gap-2">
                    <Phone className="w-4 h-4 text-gray-500" />
                    <span className="text-xs text-gray-500">Phone:</span>
                    <span className="font-medium text-sm"> {ticket?.pCodePhone}{ticket?.participantPhone}</span>
                  </div>
                </div>
                <button
                  onClick={() => handleClick(ticket._id)}
                  className="w-full flex items-center justify-center gap-2 bg-gradient-to-br from-[#d73757] to-[#eb7d87] rounded-sm text-white py-2 hover:bg-primaryColor text-md"
                >
                  <ArrowDownToLine className="w-4 h-4" />
                  Download Ticket
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <AppStorePopup
        content='Download our app to easily access all your tickets in one place and enjoy a seamless experience!'
      />
    </div>
  );
}
export default TicketsList;
