import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { XIcon } from 'lucide-react';
import Cropper from 'react-cropper';
import Select from 'react-select'
import "cropperjs/dist/cropper.css";
import { useDispatch, useSelector } from 'react-redux';
import { addEvent } from '../../../../services/Redux/slices/EventsSlice';
import { triggerNotification } from '../../Notification/Notification';
import { eventSchema } from '../../../../schema/event';

// Zod schema for form validation


const AddEvent = ({ isOpen, openModal }) => {

    const dispatch = useDispatch()

    const { types } = useSelector((state) => state.types)
    const { team } = useSelector((state) => state.teams)
    
    const [eventImage, setEventImage] = useState('');
    const [cropper, setCropper] = useState(null);
    const [showCropper, setShowCropper] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
        watch,
        control
    } = useForm({
        resolver: zodResolver(eventSchema),
        defaultValues: {
            publicUrl: false,
            image: '',
            ticketsType: [],
            team: ''
        }
    });

    // Custom styles for the team select
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: '8px 12px',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
        }),
    };

    // Custom Option component for the select
    const CustomOption = ({ children, data, ...props }) => {
        return (
            <div {...props.innerProps} className="cursor-pointer hover:bg-gray-100 py-2 px-3">
                <div className="flex items-center gap-2">
                    <span
                        className="w-4 h-4 rounded-sm"
                        style={{ backgroundColor: data.color }}
                    />
                    {children}
                </div>
            </div>
        );
    };

    // Handle image file selection
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setEventImage(reader.result);
                setShowCropper(true);
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle image cropping
    const handleCrop = () => {
        if (cropper) {
            const croppedCanvas = cropper.getCroppedCanvas({
                width: 400,
                height: 400,
            });
            const base64 = croppedCanvas.toDataURL();
            setValue('image', base64);
            setShowCropper(false);
        }
    };

    const onSubmit = (data) => {
        dispatch(addEvent(data))
        .unwrap() 
        .then(() => {
            reset();
            openModal();
            triggerNotification(
                "Event Added Successfully!",
                "The event has been successfully added.",
                "success",
                5000
            );
        })
        .catch((error) => {
            triggerNotification(
                "Error Adding Event",
                "An error occurred while adding the event. Please try again.",
                "danger",
                5000
            );
            reset();
            openModal();
        });
    }

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-sm shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
                <div className="p-6">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-2xl font-bold">Add New Event</h2>
                        <button onClick={openModal} className="text-gray-500 hover:text-gray-700">
                            <XIcon className="w-6 h-6" />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
                        {/* Event Details Section */}
                        <div className="border border-gray-200 rounded-sm p-6 shadow-md bg-gray-50">
                            <h3 className="text-lg font-semibold mb-4 text-gray-600">Event Details</h3>
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-500 mb-1">
                                        Event Name
                                    </label>
                                    <input
                                        {...register('name')}
                                        placeholder='Event name...'
                                        className="w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                    />
                                    {errors.name && (
                                        <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
                                    )}
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-500 mb-1">
                                            Start Date and Time
                                        </label>
                                        <input
                                            type="datetime-local"
                                            {...register('dateStart')}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                        />
                                        {errors.dateStart && (
                                            <p className="mt-1 text-sm text-red-600">{errors.dateStart.message}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-500 mb-1">
                                            End Date and Time
                                        </label>
                                        <input
                                            type="datetime-local"
                                            {...register('dateEnd')}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                        />
                                        {errors.dateEnd && (
                                            <p className="mt-1 text-sm text-red-600">{errors.dateEnd.message}</p>
                                        )}
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-500 mb-1">
                                            Team
                                        </label>
                                        <Controller
                                            name="team"
                                            control={control}
                                            render={({ field: { onChange, value, ...field } }) => (
                                                <Select
                                                    {...field}
                                                    value={team?.find(t => t._id === value) ? {
                                                        value: value,
                                                        label: team.find(t => t._id === value).teamName,
                                                        color: team.find(t => t._id === value).teamColor
                                                    } : null}
                                                    onChange={(option) => onChange(option ? option.value : '')}
                                                    isClearable
                                                    options={team?.map(el => ({
                                                        value: el._id,
                                                        label: el.teamName,
                                                        color: el.teamColor
                                                    }))}
                                                    className="w-full"
                                                    classNamePrefix="select"
                                                    styles={customStyles}
                                                    components={{
                                                        Option: CustomOption
                                                    }}
                                                    placeholder="Select a team..."
                                                />
                                            )}
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-500 mb-1">
                                            Ticket Type
                                        </label>
                                        <Select
                                            isMulti
                                            {...register('ticketsType')}
                                            name="ticketsType"
                                            options={types?.map(type => ({ value: type?._id, label: type?.name }))}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={(selectedOptions) => {
                                                const values = selectedOptions.map(option => option.value);
                                                setValue('ticketsType', values);
                                            }}
                                            placeholder="Select a tickets type..."
                                        />
                                        {errors.ticketsType && (
                                            <p className="mt-1 text-sm text-red-600">{errors.ticketsType.message}</p>
                                        )}
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="hasPublicUrl"
                                        {...register('publicUrl')}
                                        className="h-4 w-4 text-primaryColor focus:ring-primaryColor border-gray-300 rounded transition-colors"
                                    />
                                    <label htmlFor="publicUrl" className="ml-2 block font-medium text-sm text-gray-600">
                                        Has Public URL
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="border border-gray-200 rounded-sm p-6 shadow-sm bg-gray-50">
                            <h3 className="text-lg font-semibold mb-4 text-gray-600">Address</h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {['venue', 'rue', 'ville', 'region', 'pays', 'codePostale'].map((field) => {
                                    const fieldLabels = {
                                        venue: 'Venue',
                                        rue: 'Street',
                                        ville: 'City',
                                        region: 'Region',
                                        pays: 'Country',
                                        codePostale: 'Postal Code',
                                    };
                                    return (
                                        <div key={field}>
                                            <label 
                                                className="block text-sm font-medium text-gray-500 mb-1" 
                                            >
                                                {fieldLabels[field]}
                                            </label>
                                            <input
                                                {...register(field)}
                                                placeholder={fieldLabels[field]}   
                                                className="w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                            />
                                            {errors[field] && (
                                                <p className="mt-1 text-sm text-red-600">{errors[field].message}</p>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {/* Other Information Section */}
                        <div className="border border-gray-200 rounded-sm p-6 shadow-sm bg-gray-50">
                            <h3 className="text-lg font-semibold mb-4 text-gray-600">Other Information</h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-500 mb-1">
                                        Promoter
                                    </label>
                                    <input
                                        {...register('promoter')}
                                        placeholder="Promoter"
                                        className="w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                    />
                                    {errors.promoter && (
                                        <p className="mt-1 text-sm text-red-600">{errors.promoter.message}</p>
                                    )}
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-500 mb-1">
                                        Promoter Phone
                                    </label>
                                    <input
                                        type="tel"
                                        placeholder="Promoter phone"
                                        {...register('promoterPhone')}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                    />
                                    {errors.promoterPhone && (
                                        <p className="mt-1 text-sm text-red-600">{errors.promoterPhone.message}</p>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Image Upload Section */}
                        <div className="border border-gray-200 rounded-sm p-6 shadow-sm bg-50">
                            <h3 className="text-lg font-semibold mb-4 text-gray-600">Event Cover</h3>
                            <div className="space-y-4">
                                <div className="flex items-center">
                                    <input
                                        type="file"
                                        id="image"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="sr-only"
                                    />
                                    <label
                                        htmlFor="image"
                                        className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-sm shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                                    >
                                        Choose file
                                    </label>
                                </div>
                                {errors.image && (
                                    <p className="mt-1 text-sm text-red-600">{errors.image.message}</p>
                                )}

                                {showCropper && eventImage && (
                                    <div className="mt-4">
                                        <Cropper
                                            src={eventImage}
                                            style={{ height: 400, width: '100%' }}
                                            aspectRatio={1}
                                            guides={true}
                                            onInitialized={(instance) => setCropper(instance)}
                                        />
                                        <button
                                            type="button"
                                            onClick={handleCrop}
                                            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-sm shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors"
                                        >
                                            Crop Image
                                        </button>
                                    </div>
                                )}

                                {watch('image') && !showCropper && (
                                    <div className="mt-4">
                                        <img
                                            src={watch('image')}
                                            alt="Cropped"
                                            className="w-40 h-40 object-cover"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex justify-end space-x-3">
                            <button
                                type="button"
                                onClick={openModal}
                                className="px-4 py-2 border border-gray-300 rounded-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 border border-transparent rounded-sm shadow-sm text-sm font-medium text-white bg-primaryColor hover:bg-primaryColor focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor transition-colors"
                            >
                                Add Event
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddEvent;