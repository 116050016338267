import React, { useEffect, useState } from 'react'
import DataTable from '../components/Layout/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTicketTypeById, getTicketTypes } from '../services/Redux/slices/TicketTypes'
import AddTicketType from '../components/Core/Modals/Add/AddTicketType'
import EditTicketType from '../components/Core/Modals/Edit/EditTicketType'
import LoadingSpinner from '../components/Layout/LoadingSpinner'

function TicketTypes() {
  const { types, loading } = useSelector((state) => state.types)
  const dispatch = useDispatch()

  const [filteredType, setFilteredType] = useState()
  const [filteredPrice, setFilteredPrice] = useState()

  useEffect(() => {
    dispatch(getTicketTypes({ filteredType, filteredPrice }))
  }, [dispatch, filteredType, filteredPrice])

  const columns = [
    { label: 'Ticket Type Name', key: 'name' },
    { label: 'Price', key: 'price' },
  ]
  const toAddColumns = [
    { label: 'Ticket Type Name', key: 'name', type: 'text', required: true },
    { label: 'Price', key: 'price', type: 'number', required: true },
  ]

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedType, setSelectedType] = useState(null)



  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  const handleEdit = (updated) => {
    setIsEditModalOpen(false) // Close the edit modal after editing
  }

  const handleEditClick = (type) => {
    setSelectedType(type)
    setIsEditModalOpen(true)
  }
  return (
    <div className='container mx-auto'>
      <div className='flex items-center justify-between mb-4'>
        <p className='text-2xl font-medium'>Tickets Type</p>
        <button
          onClick={openModal}
          className='bg-[#D73556] p-2 rounded-sm text-white px-3 font-medium'
        >
          Add TicketType
        </button>
        <AddTicketType
          columns={toAddColumns}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      </div>

      <div className='border-b border-primaryColorLight pb-1 mb-4'>
        <div className='flex flex-col md:flex-row gap-4'>

          <div className='flex flex-col w-full md:w-52'>
            <label className='text-gray-500 text-sm'>
              {' '}
              Filter By <span className='text-primaryColorLight'>
                {' '}
                Ticket Type{' '}
              </span>{' '}
            </label>
            <input
              type='text'
              placeholder='Ticket Type'
              className='p-2 border rounded-sm mb-1 w-full md:w-52 h-9'
              onChange={(e) => setFilteredType(e.target.value)}
            />
          </div>

          <div className='flex flex-col w-full md:w-52'>
            <label className='text-gray-500 text-sm'>
              {' '}
              Filter By <span className='text-primaryColorLight'>
                {' '}
                Minimum Ticket Price{' '}
              </span>{' '}
            </label>
            <input
              type='number'
              min={0}
              placeholder='Ticket Price'
              className='p-2 border rounded-sm mb-1 w-full md:w-52 h-9'
              onChange={(e) => setFilteredPrice(e.target.value)}
            />
          </div>

        </div>


      </div>

      {
        loading ?
          <LoadingSpinner /> :
          <DataTable columns={columns} data={types}
            onEditClick={handleEditClick}
            onDeleteClick={(type) => dispatch(deleteTicketTypeById(type))} >
          </DataTable>
      }




      {isEditModalOpen && (
        <EditTicketType
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onEdit={handleEdit}
          type={selectedType}
          columns={toAddColumns}
        />
      )}
    </div>
  )
}


export default TicketTypes