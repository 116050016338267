import { ReactNotifications , Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

const Notification = () => {
    return <div className="notifications-container"><ReactNotifications /></div>;
}

export const triggerNotification = (title, message, type = 'success', duration) => {
    Store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        width: 400,
        dismiss: {
            duration: duration,
            onScreen: true
        }
    });
};

export default Notification;