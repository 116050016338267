import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import client from '../../../lib/config/client'
import { getToken } from '../../Functions/getToken'

const prefix = 'ticketType'

export const getTicketTypes = createAsyncThunk('ticketType', async ({filteredType,filteredPrice}, thunkApi) => {
  try {
    const response = await client.get(`${prefix}/get`, {
      params: {
        filteredType,
        filteredPrice
      },
      headers: { Authorization: `Bearer ${getToken()?.user}` }
    })

    if (response.data) return response.data
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})
export const deleteTicketTypeById = createAsyncThunk('ticketType/delete', async (id, thunkApi) => {
  try {
    const response = await client.delete(`${prefix}/delete/${id}`, {
      headers: { Authorization: `Bearer ${getToken()?.user}` }
    })

    if (response.data) return id
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})
export const addTicketType =createAsyncThunk('ticketType/add', async (data,thunkApi) => {
  try {
    const response = await client.post(`${prefix}/add`, data, {
      headers: { Authorization: `Bearer ${getToken()?.user}` }
    })

    if (response.data) return response.data
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data)
  }
})
export const updateType = createAsyncThunk(
  'ticketType/update',
  async (data, thunkApi) => {
    try {
      const response = await client.put(
        `${prefix}/update/${data._id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${getToken()?.user}`
          }
        }
      )
      if (response.data) return response.data
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data)
    }
  }
)
export const TicketTypesSlice = createSlice({
  name: 'types',
  initialState: {
    types: [],
    error: '',
    success: false,
    loading: false
  },
  reducers: {
    resetTicketType: (state) => {
      state.error = ''
      state.success = false
      state.types = []
      state.loading = false
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTicketTypes.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getTicketTypes.fulfilled, (state, action) => {
      state.types = action.payload
      state.success = true
      state.loading = false
    })
    builder.addCase(getTicketTypes.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })
    builder.addCase(deleteTicketTypeById.fulfilled, (state, action) => {
      state.types = state.types.filter((type) => type._id !== action.payload)
      state.success = true
    })
    builder.addCase(deleteTicketTypeById.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(addTicketType.fulfilled, (state, action) => {
      state.types = [action.payload,...state.types];
      state.success = true
    })
    builder.addCase(addTicketType.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(updateType.fulfilled, (state, action) => {
      state.success = true
      state.types = state.types.map((type) =>
        type._id === action.payload._id ? action.payload : type
      )
    })
    builder.addCase(updateType.rejected, (state, action) => {
      state.error = action.payload
    })
  }
})

export const { resetTicketType } = TicketTypesSlice.actions
export default TicketTypesSlice.reducer