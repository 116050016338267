import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addNewTeamMember } from '../../../../services/Redux/slices/TeamSlice'
import { AsyncPaginate } from 'react-select-async-paginate'
import { getToken } from '../../../../services/Functions/getToken'
import client from '../../../../lib/config/client'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { teamSchema } from '../../../../schema/team'



const AddTeam = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const {
    register,
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: zodResolver(teamSchema),
    defaultValues: {
      teamColor: '#d73556'
    }
  })

  const handleAddMember = (data) => {
    dispatch(addNewTeamMember(data)).then((result) => {
      if (result.meta.requestStatus === 'fulfilled') {
        onClose()
      }
    })
  }
  const handleClose = () => {
    if (!isSubmitting) {
      reset()
      onClose()
    }
  }

  async function loadOptions(search, loadedOptions, { page }) {
    const { data } = await client.get(`/get-users`, {
      params: {
        page,
        limit: 10,
        query: search
      },
      headers: { Authorization: `Bearer ${getToken()?.user}` }
    })
    const { users, totalPages, currentPage } = data
    const hasMore = totalPages > currentPage
    return {
      options: users.map((e) => ({
        label: `${e.profile.firstName} ${e.profile.lastName}`,
        value: e._id,
        avatar: e.profile.avatarId,
        slug: e.slug
      })),
      hasMore,
      additional: {
        page: page + 1
      }
    }
  }

  const [teamColor] = watch(['teamColor'])
  return (
    <div
      className={`fixed inset-0 z-50  overflow-auto ${
        isOpen ? 'flex' : 'hidden'
      }`}
    >
      <div className='fixed inset-0 bg-black bg-opacity-50'></div>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-md shadow-lg max-w-md w-full max-h-[80vh] overflow-y-auto'>
        <h2 className='text-lg font-semibold mb-4'>Add Team</h2>
        <form
          id='add-member-form'
          onSubmit={handleSubmit(handleAddMember)}
          className='flex flex-col space-y-4'
        >
          <div className='mb-4'>
            <label className='block text-sm font-medium text-gray-700 my-3'>
              Team Name & Color <span className='text-red-500 ml-1'>*</span>
            </label>
            <div className='flex'>
              <div className='relative w-10 h-10 me-2'>
                <input
                  type='color'
                  id='color-picker'
                  {...register('teamColor')}
                  className='absolute inset-0 opacity-0 w-full h-full cursor-pointer'
                />
                <div
                  className='w-10 h-10 rounded-md shadow-2xl border-2 shadow '
                  style={{ backgroundColor: teamColor }}
                  aria-hidden='true'
                />
              </div>
              <input
                type='text'
                {...register('teamName')}
                className='border border-gray-300 rounded-md p-2 w-full'
              />
            </div>{' '}
            {(errors.teamName?.message || errors.teamColor?.message) && (
              <p className='text-sm text-red-500'>
                {errors.teamColor?.message || errors.teamName?.message}
              </p>
            )}
          </div>
          <div className='mb-4'>
            <label className='block text-sm font-medium text-gray-700 my-3'>
              Sellers
            </label>

            <AsyncPaginate
              isMulti
              formatOptionLabel={(data) => <OptionsMultiSelect {...data} />}
              debounceTimeout={1000}
              loadOptions={loadOptions}
              onChange={(e) => setValue('sellers', e)}
              additional={{
                page: 1
              }}
            />
          </div>
          <div className='mb-4'>
            <label className='block text-sm font-medium text-gray-700 my-3'>
              Controllers
            </label>
            <AsyncPaginate
              isMulti
              formatOptionLabel={(data) => <OptionsMultiSelect {...data} />}
              debounceTimeout={1000}
              loadOptions={loadOptions}
              onChange={(e) => setValue('controllers', e)}
              additional={{
                page: 1
              }}
            />
          </div>

          <div className='flex justify-end'>
            <button
              onClick={handleClose}
              type='button'
              className='mr-2 px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300'
            >
              Cancel
            </button>
            <button
              disabled={isSubmitting}
              type='submit'
              form='add-member-form'
              className='px-4 py-2 bg-primaryColor text-white rounded-md hover:bg-primaryColorLight'
            >
              {isSubmitting ? 'Loading...' : 'Add'}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

const OptionsMultiSelect = ({ avatar, label, slug }) => {
  return (
    <div className='flex items-center'>
      {avatar ? (
        <img
          src={avatar}
          alt={'profile picture'}
          className='w-12 h-12  border-2 border-primaryColorLight rounded-full'
        />
      ) : (
        <div className='flex w-12 h-12 border-2 bg-primaryColor border-primaryColorLight rounded-full justify-center items-center '>
          <div className='font-bold text-white'>
            {label.slice(0, 2).toUpperCase()}
          </div>
        </div>
      )}

      <div className='ml-4'>
        <span className='font-semibold text-gray-500'>[{slug}]</span>
        <div className=''> {label}</div>
      </div>
    </div>
  )
}
export default AddTeam
