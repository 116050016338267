import React from 'react';
import PaymentButton from '../../Shared/PaymentButton';
import mcTickets from '../../../assets/images/mcp.svg'
import AppStorePopup from '../PpoUp/AppStorePopup';


const PaymentSection = ({ paymentLink, totalAmount, link, participants }) => (
  <div className="min-h-screen flex items-center justify-center p-4 overflow-hidden sm:my-0 my-8">
    <div className="w-full max-w-3xl bg-white shadow-lg rounded-sm border border-gray-200">
      <div className="p-6 flex flex-col items-center space-y-4">
        {/* Logo Section */}
        <div className="mb-2">
          <img
            src={mcTickets}
            width={250}
            alt="Company Logo"
          />
        </div>
        <h2 className="text-2xl md:text-3xl font-bold text-center text-gray-800">Ticket Invoice</h2>
      </div>
      <div className="p-6">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-200">
              <th className="text-left py-3 px-4 font-semibold text-sm text-gray-600">Ticket Type</th>
              <th className="text-left py-3 px-4 font-semibold text-sm text-gray-600">Participant</th>
              <th className="text-left py-3 px-4 font-semibold text-sm text-gray-600">Phone Number</th>
            </tr>
          </thead>
          <tbody>
            {participants.map((participant, index) => (
              <tr key={index} className="border-b border-gray-200 last:border-b-0">
                {index === 0 && (
                  <td rowSpan={participants.length} className="py-3 px-4 align-top text-sm text-gray-800">
                    {link?.ticketType?.name}
                  </td>
                )}
                <td className="py-3 px-4 text-sm text-gray-800">{participant?.name}</td>
                <td className="py-3 px-4 text-sm text-gray-800">{participant?.['pCodePhone']}{participant["phone"]}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="p-6">
        <div className="flex flex-col items-end space-y-4">
          <div className="text-xl font-semibold text-gray-800">
            Total: {totalAmount}TND
          </div>
          <PaymentButton paymentLink={paymentLink} />
        </div>
      </div>
    </div>
    <AppStorePopup
        content='Discover more events by downloading our app for a better experience on your mobile device.'
    />
  </div>
);

export default PaymentSection;