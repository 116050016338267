import { useState, useEffect } from 'react'
import { X, Apple, Play } from 'lucide-react'
import playStore from '../../../assets/images/playStore.png'
import appStore from '../../../assets/images/appStore.png'

export default function AppStorePopup({content}) {
    const [isVisible, setIsVisible] = useState(false)

    useEffect(() => {
        // Show the popup after a short delay
        const timer = setTimeout(() => setIsVisible(true), 1000)
        return () => clearTimeout(timer)
    }, [])

    return (
        <div
            className={`fixed inset-x-0 md:inset-x-auto md:left-4 md:bottom-4 md:max-w-sm w-full md:w-auto bg-white shadow-lg md:rounded-lg overflow-hidden transition-all duration-500 ease-in-out transform ${isVisible ? 'bottom-0 opacity-100' : 'translate-y-full opacity-0'
                }`}
        >
            <div className="bg-gradient-to-br from-[#d73757] to-[#eb7d87]">
                <button
                    onClick={() => setIsVisible(false)}
                    className="absolute top-2 right-2 text-white hover:text-gray-200 transition-colors"
                    aria-label="Close popup"
                >
                    <X className="h-6 w-6" />
                </button>
                <div className="p-4">
                    <h2 className="text-xl font-bold text-white  mb-2 ">Get Our App!</h2>
                    <p className="text-white text-sm mb-4">
                     {content}
                    </p>
                    <div className="flex space-x-4">

                        <button className='bg-black h-16 rounded-lg'>
                            <a
                                href="#"
                                className="flex items-center justify-center "
                            >
                                <img src={playStore} alt='time out' width={500} height={500}  />
                            </a>
                        </button>

                        <button className='bg-black h-16 rounded-lg'>
                            <a
                                href="#"
                                className="flex items-center justify-center "
                            >
                                <img src={appStore} alt='time out' width={500} height={500} />
                            </a>
                        </button>

                    </div>

                </div>
            </div>
        </div>
    )
}