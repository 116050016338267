import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { XIcon } from 'lucide-react';
import Cropper from 'react-cropper';
import Select from 'react-select'
import "cropperjs/dist/cropper.css";
import { useDispatch, useSelector } from 'react-redux';
import { updateEvent } from '../../../../services/Redux/slices/EventsSlice';
import { triggerNotification } from '../../Notification/Notification';
import { eventSchema } from '../../../../schema/event';
import { countries } from '../../../../data/countries';



const EditEvent = ({ isOpen, openModal, eventData }) => {

    const dispatch = useDispatch();
    const { types } = useSelector((state) => state.types);
    const { team } = useSelector((state) => state.teams);

    const [eventImage, setEventImage] = useState('');
    const [cropper, setCropper] = useState(null);
    const [showCropper, setShowCropper] = useState(false);

    
    const formatDateForInput = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().slice(0, 16);
    };


    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        watch,
        reset
    } = useForm({
        resolver: zodResolver(eventSchema),
        defaultValues: {
            name: eventData?.name || '',
            dateStart: eventData ? formatDateForInput(eventData.dateStart) : '',
            dateEnd: eventData ? formatDateForInput(eventData.dateEnd) : '',
            team: eventData?.team?._id || '',
            ticketsType: eventData?.ticketsType?.map(ticket => ticket._id) || [],
            publicUrl: eventData?.publicUrl || false,
            address: eventData?.address?._id,
            venue: eventData?.address?.venue || '',
            rue: eventData?.address?.rue || '',
            ville: eventData?.address?.ville || '',
            region: eventData?.address?.region || '',
            pays: eventData?.address?.pays || '',
            codePostale: eventData?.address?.codePostale || '',
            promoter: eventData?.promoter || '',
            codePhone: eventData?.codePhone || '' ,
            promoterPhone: eventData?.promoterPhone || '',
            image: eventData?.image || '',
        }
    });

    useEffect(() => {
        if (eventData) {
            Object.keys(eventData).forEach(key => {
                if (key === 'dateStart' || key === 'dateEnd') {
                    setValue(key, formatDateForInput(eventData[key]));
                } else if (key === 'address') {
                    setValue(key, eventData[key]?._id);
                } else if (key === 'team') {
                    setValue(key, eventData[key]?._id || "");
                } else if(key === 'ticketsType'){
                    setValue(key, eventData[key]?.map((el)=>el?._id) || "");
                }else {
                    setValue(key, eventData[key]);
                }
            });
        }
    }, [eventData, setValue]);

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            padding: '8px 12px',
            borderRadius: '0', // Added rounded 0
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            borderRadius: '0', // Added rounded 0
        }),
        control: (provided,state) => ({
            ...provided,
            height:"41px",
            borderRadius: '2px', // Added rounded 0 for the control
            boxShadow: 'none',
            '&:focus-within': {
                borderColor: '#D73556',
                boxShadow: state.isFocused ? '0 0 0 1px #D73556' : 'none', // Changed to focus:ring-2
            }    
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '0', // Added rounded 0 for the dropdown menu
        })
     };



    const CustomOption = ({ children, data, ...props }) => {
        return (
            <div {...props.innerProps} className="cursor-pointer hover:bg-gray-100 py-2 px-3">
                <div className="flex items-center gap-2">
                    <span
                        className="w-4 h-4 rounded-sm"
                        style={{ backgroundColor: data.color }}
                    />
                    {children}
                </div>
            </div>
        );
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setEventImage(reader.result);
                setShowCropper(true);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleCrop = () => {
        if (cropper) {
            const croppedCanvas = cropper.getCroppedCanvas({
                width: 400,
                height: 400,
            });
            const base64 = croppedCanvas.toDataURL();
            setValue('image', base64);
            setShowCropper(false);
        }
    };

    const onSubmit = (data) => {
        dispatch(updateEvent({ _id: eventData._id, ...data }))
            .unwrap()
            .then(() => {
                openModal();
                triggerNotification(
                    "Event Updated Successfully!",
                    "The event has been successfully updated.",
                    "success",
                    5000
                );
            })
            .catch((error) => {
                triggerNotification(
                    "Error Updating Event",
                    "An error occurred while updating the event. Please try again.",
                    "danger",
                    5000
                );
            });
    };

    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-sm shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
                <div className="p-6">
                    <div className="flex justify-between items-center mb-6">
                        <h2 className="text-2xl font-bold">Update Event</h2>
                        <button onClick={openModal} className="text-gray-500 hover:text-gray-700">
                            <XIcon className="w-6 h-6" />
                        </button>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
                        {/* Event Details Section */}
                        <div className="border border-gray-200 rounded-sm p-6 shadow-md bg-gray-50">
                            <h3 className="text-lg font-semibold mb-4 text-gray-600">Event Details</h3>
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-500 mb-1">
                                        Event Name
                                    </label>
                                    <input
                                        {...register('name')}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                    />
                                    {errors.name && (
                                        <p className="mt-1 text-sm text-red-600">{errors.name.message}</p>
                                    )}
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-500 mb-1">
                                            Start Date and Time
                                        </label>
                                        <input
                                            type="datetime-local"
                                            {...register('dateStart')}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                        />
                                        {errors.dateStart && (
                                            <p className="mt-1 text-sm text-red-600">{errors.dateStart.message}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-500 mb-1">
                                            End Date and Time
                                        </label>
                                        <input
                                            type="datetime-local"
                                            {...register('dateEnd')}
                                            className="w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                        />
                                        {errors.dateEnd && (
                                            <p className="mt-1 text-sm text-red-600">{errors.dateEnd.message}</p>
                                        )}
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                    <div>
                                        <label className="block text-sm font-medium text-gray-500 mb-1">
                                            Team
                                        </label>
                                        <Controller
                                            name="team"
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                const selectedTeam = value ? team?.find(t => t._id === value) : null;
                                                return (
                                                    <Select
                                                        value={selectedTeam ? {
                                                            value: selectedTeam._id,
                                                            label: selectedTeam.teamName,
                                                            color: selectedTeam.teamColor
                                                        } : null}
                                                        onChange={(option) => {
                                                            // Pass null when clearing the selection
                                                            onChange(option ? option.value : "");
                                                        }}
                                                        isClearable={true}
                                                        options={team?.map(el => ({
                                                            value: el._id,
                                                            label: el.teamName,
                                                            color: el.teamColor
                                                        }))}
                                                        className="w-full"
                                                        classNamePrefix="select"
                                                        styles={customStyles}
                                                        components={{
                                                            Option: CustomOption
                                                        }}
                                                        placeholder="Select a team..."
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-500 mb-1">
                                            Ticket Type
                                        </label>
                                        <Controller
                                            name="ticketsType"
                                            control={control}
                                            render={({ field: { onChange, value } }) => {
                                                const selectedValues = types?.filter(type =>
                                                    value.includes(type._id))
                                                    .map(type => ({
                                                        value: type._id,
                                                        label: type.name
                                                    }));

                                                return (
                                                    <Select
                                                        isMulti
                                                        value={selectedValues}
                                                        options={types?.map(type => ({
                                                            value: type._id,
                                                            label: type.name
                                                        }))}
                                                        onChange={(selectedOptions) => {
                                                            onChange(selectedOptions ? selectedOptions.map(option => option.value) : []);
                                                        }}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        styles={customStyles}
                                                    />
                                                );
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="flex items-center">
                                    <input
                                        type="checkbox"
                                        id="publicUrl"
                                        {...register('publicUrl')}
                                        className="h-4 w-4 text-primaryColor focus:ring-primaryColor border-gray-300 rounded transition-colors"
                                    />
                                    <label htmlFor="publicUrl" className="ml-2 block font-medium text-sm text-gray-600">
                                        Has Public URL
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Address Section */}
                        <div className="border border-gray-200 rounded-sm p-6 shadow-sm bg-gray-50">
                            <h3 className="text-lg font-semibold mb-4 text-gray-600">Address</h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {['venue', 'rue', 'ville', 'region', 'pays', 'codePostale'].map((field) => {
                                    const fieldLabels = {
                                        venue: 'Venue',
                                        rue: 'Street',
                                        ville: 'City',
                                        region: 'Region',
                                        pays: 'Country',
                                        codePostale: 'Postal Code',
                                    };
                                    if (field === 'pays') {
                                        return (
                                            <div key={field}>
                                                <label className="block text-sm font-medium text-gray-500 mb-1">
                                                    {fieldLabels[field]}
                                                </label>
                                                <Controller
                                                    name={field}
                                                    control={control}
                                                    render={({ field: { onChange, value, ...inputProps } }) => {
                                                        const selectedCountry = value ? countries?.find(t => t?.name == value) : null;
                                                        return(
                                                        <Select
                                                            {...inputProps}
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            placeholder="Select a country ..."
                                                            value={selectedCountry ? {
                                                                value: selectedCountry?.name,
                                                                label: `${selectedCountry?.emoji} ${selectedCountry?.name}`,
                                                            } : null}
                                                            onChange={(selectedOption) => onChange(selectedOption ? selectedOption.value : '')}
                                                            options={countries?.map((country) => ({
                                                                value: country.name,
                                                                label: `${country.emoji} ${country.name}`,
                                                            }))}
                                                            styles={customStyles}
                                                        />)
                                                    }}
                                                />
                                                {errors.pays && (
                                                    <p className="mt-1 text-sm text-red-600">{errors.pays.message}</p>
                                                )}
                                            </div>
                                        );
                                    }
                                    return (
                                        <div key={field}>
                                            <label className="block text-sm font-medium text-gray-500 mb-1">
                                                {fieldLabels[field]}
                                            </label>
                                            <input
                                                {...register(field)}
                                                className="w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                            />
                                            {errors[field] && (
                                                <p className="mt-1 text-sm text-red-600">{errors[field].message}</p>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {/* Other Information Section */}
                        <div className="border border-gray-200 rounded-sm p-6 shadow-sm bg-gray-50">
                            <h3 className="text-lg font-semibold mb-4 text-gray-600">Other Information</h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-500 mb-1">
                                        Promoter
                                    </label>
                                    <input
                                        {...register('promoter')}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                    />
                                    {errors.promoter && (
                                        <p className="mt-1 text-sm text-red-600">{errors.promoter.message}</p>
                                    )}
                                </div>

                                <div className='relative rounded-xl transition-all duration-300 '>   
                                    
                                    <label className="block text-sm font-medium text-gray-500 mb-1">
                                        Promoter Phone Number
                                    </label>
                                 
                                    <div className="flex">
                                        <select
                                            className="block  bg-white border border-gray-300  text-gray-700 font-medium  w-36 focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                            {...register('codePhone')}
                                            placeholder="Code"
                                        >
                                             <option value="" disabled>
                                                Code
                                            </option>
                                            {countries?.map(({ dial_code , emoji , i }) => (
                                                <option key={i} value={dial_code}>
                                                    {emoji} | {dial_code}
                                                </option>
                                            ))}
                                        </select>

                                        <div className="w-px bg-gray-200 self-stretch my-2" />

                                        <input
                                            type="tel"
                                            placeholder="Enter phone number"
                                            className="block w-full pl-4 h-10 border border-gray-300 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                            {...register('promoterPhone')}    
                                        />

                                      
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Image Upload Section */}
                        <div className="border border-gray-200 rounded-sm p-6 shadow-sm bg-gray-50">
                            <h3 className="text-lg font-semibold mb-4 text-gray-600">Event Image</h3>
                            <div className="space-y-4">
                                <div className="flex items-center">
                                    <input
                                        type="file"
                                        id="image"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="sr-only"
                                    />
                                    <label
                                        htmlFor="image"
                                        className="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-sm shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                                    >
                                        Choose new image
                                    </label>
                                </div>
                                {errors.image && (
                                    <p className="mt-1 text-sm text-red-600">{errors.image.message}</p>
                                )}

                                {showCropper && eventImage && (
                                    <div className="mt-4">
                                        <Cropper
                                            src={eventImage}
                                            style={{ height: 400, width: '100%' }}
                                            aspectRatio={1}
                                            guides={true}
                                            onInitialized={(instance) => setCropper(instance)}
                                        />
                                        <button
                                            type="button"
                                            onClick={handleCrop}
                                            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-sm shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors"
                                        >
                                            Crop Image
                                        </button>
                                    </div>
                                )}

                                {watch('image') && !showCropper && (
                                    <div className="mt-4">
                                        <img
                                            src={watch('image')}
                                            alt="Event"
                                            className="w-40 h-40 object-cover rounded-sm"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* Form Buttons */}
                        <div className="flex justify-end space-x-3">
                            <button
                                type="button"
                                onClick={openModal}
                                className="px-4 py-2 border border-gray-300 rounded-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 border border-transparent rounded-sm shadow-sm text-sm font-medium text-white bg-primaryColor hover:bg-primaryColor/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primaryColor transition-colors"
                            >
                                Update Event
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EditEvent;