import { z } from 'zod';


export const linkSchema = z.object({
    buyerName: z.string().optional(),
    buyerEmail: z.string().optional(),
    ExpiryDate: z.string().nonempty({ message: "Expiry Date is required" }),
    event: z.string().nonempty({ message: "Event is required" }),
    ticketType: z.string().nonempty({ message: "Ticket type is required" }),
    nbTicketMax: z.number().min(1, { message: "Minimum 1 ticket required" }),
    numTel: z.string().optional(),
});