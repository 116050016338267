import axios from 'axios'

class ApiClient {
  constructor(baseURL) {
    if (!baseURL) {
      throw new Error('Base URL not defined')
    }

    this.axiosInstance = axios.create({
      baseURL
    })

    // Request interceptor
    this.axiosInstance.interceptors.request.use(
      (config) => {
        // Get fresh token for each request
        const token = this.getStoredToken()
        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        } else {
          // If no token, remove Authorization header
          delete config.headers.Authorization
        }
        return config
      },
      (error) => {
        console.error('Request error:', error)
        return Promise.reject(error)
      }
    )

    // Response interceptor
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response?.status === 401) {
          this.handleTokenExpiration()
        }
        return Promise.reject(error)
      }
    )
  }

  // Retrieve fresh token from localStorage
  getStoredToken() {
    try {
      const storedUser = JSON.parse(localStorage.getItem('user'))
      if (!storedUser || !storedUser.token) {
        return null
      }

      // Optional: Add token validation
      if (this.isTokenExpired(storedUser.token)) {
        this.handleTokenExpiration()
        return null
      }

      return storedUser.token
    } catch (error) {
      console.error('Error getting stored token:', error)
      localStorage.removeItem('user')
      return null
    }
  }

  // Validate token expiration
  isTokenExpired(token) {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]))
      return payload.exp * 1000 < Date.now()
    } catch {
      return true
    }
  }

  // Store token in localStorage
  setToken(token) {
    try {
      const user = JSON.parse(localStorage.getItem('user')) || {}
      user.token = token
      localStorage.setItem('user', JSON.stringify(user))
    } catch (error) {
      console.error('Error setting token:', error)
      this.handleTokenExpiration()
    }
  }

  // Handle token expiration
  handleTokenExpiration() {
    // Clear all auth-related storage
    localStorage.removeItem('user')
    sessionStorage.clear()
    
    // Clear cookies
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    })

    // If you have a global state management (Redux/Zustand)
    if (window.store?.dispatch) {
      window.store.dispatch({ type: 'auth/clearAuth' })
    }

    // Redirect only if not already on login page
    if (!window.location.pathname.includes('/login')) {
      window.location.href = '/login'
    }
  }

  // Logout method
  async logout() {
    try {
      const token = this.getStoredToken()
      if (token) {
        // Try to invalidate token on server
        await this.post('/auth/logout', {}, {
          headers: { Authorization: `Bearer ${token}` }
        })
      }
    } catch (error) {
      console.error('Logout error:', error)
    } finally {
      this.handleTokenExpiration()
    }
  }

  async request(config) {
    try {
      return await this.axiosInstance.request(config)
    } catch (error) {
      if (error.response?.status === 401) {
        this.handleTokenExpiration()
      }
      throw error
    }
  }

  async get(url, config = {}) {
    return this.request({ ...config, method: 'GET', url })
  }

  async post(url, data, config = {}) {
    return this.request({ ...config, method: 'POST', url, data })
  }

  async put(url, data, config = {}) {
    return this.request({ ...config, method: 'PUT', url, data })
  }

  async delete(url, config = {}) {
    return this.request({ ...config, method: 'DELETE', url })
  }

  async postForm(url, data, config = {}) {
    return this.request({
      ...config,
      method: 'POST',
      url,
      data,
      headers: {
        ...config.headers,
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  async login(credentials) {
    try {
      const response = await this.post('/auth/login', credentials)
      if (response.data?.token) {
        this.setToken(response.data.token)
      }
      return response
    } catch (error) {
      console.error('Login error:', error)
      throw error
    }
  }
}

const BASE_URL = 'https://eventsapi.mineclap.com'
const client = new ApiClient(BASE_URL)

// If using Redux, store the store instance
if (window.store) {
  client.store = window.store
}

export default client
