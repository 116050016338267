import React, { useEffect, useState } from 'react'
import DataTable from '../components/Layout/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from '../hooks/useMediaQuery'

import LoadingSpinner from '../components/Layout/LoadingSpinner'
import {
  deleteTeam,
  getMyTeamMembers
} from '../services/Redux/slices/TeamSlice'
import AddTeam from '../components/Core/Modals/Add/AddTeam'
import EditTeam from '../components/Core/Modals/Edit/EditTeam'

function RenderUsers(data, mobile) {
  return (
    <div className='flex -space-x-2' data-id={10}>
      {data.slice(0, mobile ? 2 : 4).map((e) => {
        const avatar = e?.profile?.avatarId
        const name = `${e?.profile?.firstName} ${e?.profile?.lastName}`
        return (
          <span
            className='relative flex shrink-0 overflow-hidden rounded-full h-8 w-8 lg:w-12 lg:h-12 border-2 bg-primaryColor border-primaryColorLight'
            data-id={14}
            data-state='closed'
          >
            {avatar ? (
              <img
                className='aspect-square h-full w-full'
                data-id={15}
                alt={name}
                src={avatar}
              />
            ) : (
              <div className='aspect-square h-full w-full flex justify-center items-center'>
                <span className='font-bold text-white'>
                  {name.slice(0, 2).toUpperCase()}
                </span>
              </div>
            )}
          </span>
        )
      })}
      {data?.length > (mobile ? 2 : 4) && (
        <span
          className='relative flex shrink-0 overflow-hidden rounded-full h-8 w-8 lg:w-12 lg:h-12 border-2 bg-gray-400 border-primaryColorLight'
          data-id={14}
          data-state='closed'
        >
          <div className='aspect-square h-full w-full flex justify-center items-center'>
            <span className='font-bold text-white'>+{data.length - 4}</span>
          </div>
        </span>
      )}
    </div>
  )
}
const Team = () => {
  const mobile = useMediaQuery('(min-width: 320px) and (max-width: 768px)')

  const { team, loading } = useSelector((state) => state.teams)
  const dispatch = useDispatch()

  const [teamName, setTeamName] = useState()

  useEffect(() => {
    dispatch(getMyTeamMembers({teamName}))
  }, [dispatch,teamName])

  const columns = [
    {
      label: 'Color',
      key: 'teamColor',
      render: (props) => {
        return (
          <div
            style={{ backgroundColor: props?.teamColor }}
            className={`w-10 h-4 rounded-md  shadow border`}
          ></div>
        )
      }
    },
    { label: 'Name', key: 'teamName' },
    {
      label: 'Sellers',
      key: 'sellers',
      render: (props) => RenderUsers(props.sellers, mobile)
    },
    {
      label: 'Controllers',
      key: 'controllers',
      render: (props) => RenderUsers(props.controllers, mobile)
    }
  ]

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedTeam, setSelectedTeam] = useState(null)
  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)

  useEffect(() => {
    if (selectedTeam) {
      setIsEditModalOpen(true)
    }
  }, [selectedTeam])

  const handleEdit = async (updatedEvent) => {
    setIsEditModalOpen(false)
  }

  const handleEditClick = (team) => {
    const restructuredTeam = {
      ...team,
      controllers: team.controllers.map((e) => ({
        label: `${e.profile.firstName} ${e.profile.lastName}`,
        value: e._id,
        avatar: e.profile.avatarId,
        slug: e.slug
      })),
      sellers: team.sellers.map((e) => ({
        label: `${e.profile.firstName} ${e.profile.lastName}`,
        value: e._id,
        avatar: e.profile.avatarId,
        slug: e.slug
      }))
    }

    setSelectedTeam(restructuredTeam)
    setIsEditModalOpen(true)
  }

  return (
    <div className='container mx-auto'>
      <div className='flex items-center justify-between mb-4'>
        <p className='text-2xl font-medium'>Team's</p>
        <button
          onClick={openModal}
          className='bg-[#D73556] p-2 rounded-sm text-white px-3 font-medium'
        >
          Add New Team
        </button>
        <AddTeam isOpen={isModalOpen} onClose={closeModal} />
      </div>

      <div className='border-b border-primaryColorLight pb-1 mb-4'>
        <div className='flex flex-col md:flex-row gap-4'>

          <div className='flex flex-col w-full md:w-52'>
            <label className='text-gray-500 text-sm'>
              {' '}
              Filter By <span className='text-primaryColorLight'>
                {' '}
                Team Name{' '}
              </span>{' '}
            </label>
            <input
              type='text'
              placeholder='Team name'
              className='p-2 border rounded-sm mb-1 w-full md:w-52 h-9'
              onChange={(e) => setTeamName(e.target.value)}
            />
          </div>

        </div>


      </div>

      {loading && !isModalOpen ? (
        <LoadingSpinner />
      ) : (
        <DataTable
          columns={columns}
          data={team}
          onEditClick={handleEditClick}
          onDeleteClick={(id) => dispatch(deleteTeam(id))}
        ></DataTable>
      )}
      {isEditModalOpen && (
        <EditTeam
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onEdit={handleEdit}
          selectedTeam={selectedTeam}
        />
      )}
    </div>
  )
}

export default Team
