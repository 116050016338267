import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  changeFreePaymmentStatus,
  createPaymentLink,
  getLink,
  getPaymentInfo,
  getTicketsByLinkId
} from '../services/Redux/slices/LinksSlice';
import {
  addNewTicket,
  downloadTicketPdf
} from '../services/Redux/slices/tickets';
import { unwrapResult } from '@reduxjs/toolkit';
import LoadingSpinner from '../components/Layout/LoadingSpinner';
import Error from './Error';
import GenerateTicket from '../components/Core/Links&Tickets/GenerateTicket';
import PaymentSection from '../components/Core/Links&Tickets/PaymentSection';
import TicketsList from '../components/Core/Links&Tickets/TicketsList';

function GeneratedLink() {
  const { id } = useParams();
  const { link, loading, error, paymentLink, paymentStatus, tickets, statusCode } =
    useSelector((state) => state.links);
  const dispatch = useDispatch();
  const [participants, setParticipants] = useState([{ name: '', phone: '' }]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [validationError, setValidationError] = useState('');
  const [exceededError, setExceedError] = useState('');
  const [freePaymentDone, setFreePaymentDone] = useState(false);
  const [onSubmit, setOnSubmit] = useState(false);

  useEffect(() => {
    dispatch(getLink(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (link) {
      dispatch(getPaymentInfo(id));
      dispatch(getTicketsByLinkId(id));
    }
  }, [dispatch, id, freePaymentDone, link]);

  useEffect(() => {
    if (paymentStatus === 'completed') {
      link.participants.forEach((participant) => {
        dispatch(
          addNewTicket({
            link: link?._id,
            buyer: link?.buyerName,
            buyerEmail: link?.buyerEmail,
            event: link?.event._id,
            type: link.ticketType._id,
            numTel: link.numTel,
            participant: participant.name,
            participantPhone: participant.phone
          })
        );
      });
    }
  }, [paymentStatus, link, dispatch]);

  const addParticipant = useCallback(() => {
    if (participants.length < link.nbTicketMax) {
      setParticipants([...participants, { name: '', phone: '' }]);
    } else {
      setExceedError(
        `Number of allowed participants exceeded. Maximum allowed is ${link.nbTicketMax}.`
      );
    }
  }, [participants, link?.nbTicketMax]);

  const removeParticipant = useCallback(
    (index) => {
      if (participants.length > 1) {
        const updatedParticipants = [...participants];
        updatedParticipants.splice(index, 1);
        setParticipants(updatedParticipants);
        setExceedError('');
      }
    },
    [participants]
  );

  const handleParticipantChange = useCallback(
    (index, field, value) => {
      const updatedParticipants = [...participants];
      updatedParticipants[index][field] = value;
      setParticipants(updatedParticipants);
    },
    [participants]
  );

  const validateParticipants = useCallback(() => {
    for (const participant of participants) {
      if (!participant.name || !participant.phone) {
        setValidationError(
          'All participant names and phone numbers must be filled.'
        );
        return false;
      }
    }

    const uniqueParticipants = new Set(
      participants.map(
        (participant) => `${participant.name}-${participant.phone}`
      )
    );

    if (uniqueParticipants.size !== participants.length) {
      setValidationError(
        'No two participants can have the same name and phone number.'
      );
      return false;
    }

    setValidationError('');
    return true;
  }, [participants]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateParticipants()) return;

    const data = {
      amount: link.ticketType?.price * participants.length,
      id: link._id,
      participants: participants
    };
    setOnSubmit(true);
    try {
      if (link.ticketType?.price === 0) {
        for (const participant of participants) {
          const resultAction = await dispatch(
            addNewTicket({
              link: link?._id,
              buyer: link?.buyerName,
              buyerEmail: link?.buyerEmail,
              event: link?.event._id,
              type: link.ticketType._id,
              numTel: link.numTel,
              participant: participant.name,
              participantPhone: participant.phone
            })
          );
          unwrapResult(resultAction);
        }
        const paymentStatusAction = await dispatch(
          changeFreePaymmentStatus(link._id)
        );
        unwrapResult(paymentStatusAction);
        setFreePaymentDone(true);
      } else {
        const paymentLinkAction = await dispatch(createPaymentLink(data));
        unwrapResult(paymentLinkAction);
      }

      setTotalAmount(link.ticketType?.price * participants.length);
    } catch (error) {
      console.error('Failed to process the request:', error);
    } finally {
      setOnSubmit(false);
    }
  };

  const handleClick = (id) => {
    dispatch(downloadTicketPdf(id));
  };



  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Error statusCode={statusCode} />;
  }

  return (
    <div className='bg-gray-100'>
      <div>
        {link &&
          !paymentLink &&
          paymentStatus !== 'completed' &&
          !freePaymentDone &&
          !link.freePaymentIsDone && (

            <GenerateTicket
              link={link}
              participants={participants}
              handleParticipantChange={handleParticipantChange}
              removeParticipant={removeParticipant}
              addParticipant={addParticipant}
              exceededError={exceededError}
              validationError={validationError}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
            />
          )}


        {/* payment section */}

        {link && paymentLink && paymentStatus !== 'completed' && (
          <PaymentSection paymentLink={paymentLink} totalAmount={totalAmount} link={link} participants={participants} />
        )}


        {/* ticket list */}
        {(paymentStatus === 'completed' ||
          freePaymentDone ||
          link?.freePaymentIsDone) && link && (
            <>
              <TicketsList tickets={tickets} handleClick={handleClick} link={link} />

            </>
          )}
      </div>
    </div>
  );
}

export default GeneratedLink;