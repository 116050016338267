import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import client from '../../../lib/config/client'
import { getToken } from '../../Functions/getToken'

const user = getToken()

const initialState = {
  user: user ? user : null,
  loading: false,
  success: false,
  error: null,
  message: '',
  isAuthenticated: !!user
}

const clearAuthData = () => {
  localStorage.removeItem('user')
  sessionStorage.clear()
  // Clear cookies
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/")
  })
}

export const login = createAsyncThunk(
  'auth/login',
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await client.post('/login', credentials)
      
      if (response.data) {
        localStorage.setItem('user', JSON.stringify(response.data))
        return response.data
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Login failed')
    }
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue }) => {
    try {
      await client.post('/auth/logout', {})
    } catch (error) {
      console.error('Logout server error:', error)
    } finally {
      clearAuthData()
      return null
    }
  }
)

export const confirmPassword = createAsyncThunk(
  'auth/confirmPassword',
  async (password, { rejectWithValue }) => {
    try {
      const token = getToken()?.user
      if (!token) {
        throw new Error('No authentication token found')
      }

      const response = await client.post('/confirmPassword', password, {
        headers: { Authorization: `Bearer ${token}` }
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Password confirmation failed')
    }
  }
)

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const token = getToken()?.user
      if (!token) {
        throw new Error('No authentication token found')
      }

      const response = await client.put('/change-password', data, {
        headers: { Authorization: `Bearer ${token}` }
      })

      // If password change requires re-authentication
      if (response.data?.requireReauth) {
        await dispatch(logout())
      }

      return response.data
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Password change failed')
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.loading = false
      state.success = false
      state.error = null
      state.message = ''
    },
    clearError: (state) => {
      state.error = null
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(login.pending, (state) => {
        state.loading = true
        state.error = null
        state.success = false
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false
        state.success = true
        state.user = action.payload
        state.isAuthenticated = true
        state.error = null
        state.message = 'Login successful'
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.user = null
        state.isAuthenticated = false
        state.error = action.payload
        state.message = 'Login failed'
      })

      // Logout
      .addCase(logout.pending, (state) => {
        state.loading = true
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false
        state.success = true
        state.user = null
        state.isAuthenticated = false
        state.error = null
        state.message = 'Logout successful'
      })
      .addCase(logout.rejected, (state) => {
        state.loading = false
        // Still clear the user state even if server logout fails
        state.user = null
        state.isAuthenticated = false
        state.message = 'Logged out'
      })

      // Confirm Password
      .addCase(confirmPassword.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(confirmPassword.fulfilled, (state) => {
        state.loading = false
        state.success = true
        state.error = null
        state.message = 'Password confirmed'
      })
      .addCase(confirmPassword.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = action.payload
        state.message = 'Password confirmation failed'
      })

      // Change Password
      .addCase(changePassword.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading = false
        state.success = true
        state.error = null
        state.message = 'Password changed successfully'
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false
        state.success = false
        state.error = action.payload
        state.message = 'Password change failed'
      })
  }
})

export const { reset, clearError } = authSlice.actions
export default authSlice.reducer