import React from 'react'
import { XIcon } from 'lucide-react'
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDispatch, useSelector } from 'react-redux';
import { addLink } from '../../../../services/Redux/slices/LinksSlice';
import { linkSchema } from '../../../../schema/link';
import { countries } from '../../../../data/countries';
import Select from 'react-select'

const AddLink = ({ isOpen, openModal, event }) => {

    const dispatch = useDispatch()

    const { loading } = useSelector((state) => state.links)


    const { register, handleSubmit, formState: { errors, isSubmitting, isLoading }, control, reset } = useForm({
        resolver: zodResolver(linkSchema),
        defaultValues: {
            event: event?._id || "",
        },
    });

    const onSubmit = (data) => {
        dispatch(addLink(data))
            .then(() => {
                reset()
                openModal()
                //display notification
            }).catch((err) => {
                console.log('err', err)
            });
    };

    const countryOptions = countries?.map(({ dial_code, emoji, i }) => ({
        value: dial_code,
        label: `${emoji} | ${dial_code}`,
    }));


    return (
        <div className="p-4">
            {isOpen && (
                <div className="fixed -inset-0 z-50 bg-gray-600 bg-opacity-60 flex justify-center items-center p-4">
                    <div className="bg-white rounded-sm shadow-xl max-w-md w-full max-h-[90vh] overflow-y-auto">
                        <div className="relative">
                            <button
                                onClick={() => {
                                    openModal();
                                    reset();
                                }}
                                className="absolute top-7 right-2 text-white bg-black bg-opacity-50 rounded-full p-1 hover:bg-opacity-75 transition-colors"
                            >
                                <XIcon size={18} />
                            </button>
                        </div>
                        <form className="p-6 space-y-4" onSubmit={handleSubmit(onSubmit)}>
                            <h2 className="text-2xl font-bold mb-4 text-gray-800">
                                Ticketing for <span className="text-primaryColor">{event?.name}</span>
                            </h2>
                            <div>
                                <label htmlFor="buyerName" className="block text-sm font-medium text-gray-700 mb-1">Buyer Name</label>
                                <input
                                    type="text"
                                    id="buyerName"
                                    name="buyerName"
                                    placeholder='Buyer Name'
                                    className="w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-primaryColorLight"
                                    {...register("buyerName")}
                                />
                            </div>
                            <div>
                                <label htmlFor="buyerEmail" className="block text-sm font-medium text-gray-700 mb-1">Buyer Email</label>
                                <input
                                    type="email"
                                    id="buyerEmail"
                                    name="buyerEmail"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-primaryColorLight"
                                    placeholder='Buyer Email'
                                    {...register("buyerEmail")}
                                />
                            </div>
                            <div>
                                <label htmlFor="numTel" className="block text-sm font-medium text-gray-700 mb-1">
                                    Buyer Phone Number
                                </label>
                                <div className="flex">
                                    <Controller
                                        name="bCodePhone"
                                        control={control}
                                        render={({ field: { onChange, value } })=> (
                                            <Select
                                                options={countryOptions}
                                                placeholder="Select"
                                                value={countryOptions.find(option => option.value === value)}
                                                onChange={(selectedOption) => onChange(selectedOption?.value)}
                                                styles={{
                                                    control: (base, state) => ({
                                                        ...base,
                                                        width: '9rem',
                                                        height: "40px",
                                                        borderRadius: '1px',
                                                        boxShadow: 'none',
                                                        '&:focus-within': {
                                                            borderColor: '#D73556',
                                                            boxShadow: state.isFocused ? '0 0 0 1px #D73556' : 'none',
                                                        }
                                                    }),
                                                }}
                                            />
                                        )}
                                    />
                                    <input
                                        type="tel"
                                        placeholder="Enter phone number"
                                        className="block ms-1 w-full pl-4 h-10 border border-gray-300 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                        {...register('numTel')}
                                    />
                                </div>
                            </div>
                            <div>
                                <label htmlFor="ticketType" className="block text-sm font-medium text-gray-700 mb-1">Ticket Type <span className="text-red-500">*</span> </label>
                                <select
                                    id="ticketType"
                                    name="ticketType"
                                    className="w-full px-3 py-2 border bg-white border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-primaryColorLight"
                                    required
                                    {...register("ticketType")}
                                >
                                    <option value="">Select a ticket type</option>
                                    {
                                        event?.ticketsType?.map((type) => (
                                            <option key={type?._id} value={type?._id}>{type?.name}</option>
                                        ))
                                    }
                                </select>
                                {errors.ticketType && <p className="text-red-500">{errors.ticketType.message}</p>}
                            </div>
                            <div>
                                <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-700 mb-1">Expiry Date <span className="text-red-500">*</span> </label>
                                <input
                                    type="datetime-local"
                                    id="expiryDate"
                                    name="expiryDate"
                                    className="w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-primaryColorLight"
                                    required
                                    {...register("ExpiryDate")}
                                />
                                {errors.ExpiryDate && <p className="text-red-500">{errors.ExpiryDate.message}</p>}
                            </div>
                            <div>
                                <label htmlFor="nbTicketMax" className="block text-sm font-medium text-gray-700 mb-1">Max Ticket Number <span className="text-red-500">*</span> </label>
                                <input
                                    type="number"
                                    id="nbTicketMax"
                                    name="nbTicketMax"
                                    min="1"
                                    defaultValue={1}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-sm shadow-sm focus:outline-none focus:ring-2 focus:ring-primaryColorLight"
                                    required
                                    {...register("nbTicketMax", { valueAsNumber: true })}
                                />
                                {errors.nbTicketMax && <p className="text-red-500">{errors.nbTicketMax.message}</p>}
                            </div>

                            <button
                                type="submit"
                                disabled={loading}
                                className={`w-full  text-white py-2 px-4 rounded-sm  focus:outline-none transition-colors ${loading ? 'bg-gray-400' : 'bg-primaryColorLight hover:bg-[#ae2a44]'}`}
                            >
                                {loading ? 'Loading...' : 'Generate Ticket Link'}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddLink