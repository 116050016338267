import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import {
    CalendarIcon,
    CheckCheck,
    MapPinned,
    PhoneIcon,
    UserIcon
} from 'lucide-react'
import { PiDiscoBall } from "react-icons/pi";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getEventById } from '../services/Redux/slices/EventsSlice'
import LoadingSpinner from '../components/Layout/LoadingSpinner'
import Error from './Error'
import mcTickets from '../assets/images/mcp.svg'
import { addPublicLink } from '../services/Redux/slices/LinksSlice'
import 'react-notifications-component/dist/theme.css';
import { triggerNotification } from '../components/Core/Notification/Notification'
import AppStorePopup from '../components/Core/PpoUp/AppStorePopup';
import { countries } from '../data/countries';
import Select from 'react-select'


const EventDetails = ({ event }) => {
    const isEventEnded = () => {
        if (!event?.dateEnd) return false
        const eventEndDate = new Date(event.dateEnd)
        const currentDate = new Date()
        const isEnded = eventEndDate < currentDate
        return isEnded
    }

    const isEventSoldOut = () => {
        const isSoldOut = event?.ticketsType?.length === 0
        return isSoldOut
    }

    const isEventUnavailable = () => {
        const unavailable = isEventEnded() || isEventSoldOut()
        return unavailable
    }


    const SDate = new Date(event?.dateStart);
    const eDate = new Date(event?.dateEnd);

    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
    };

    const startDate = SDate.toLocaleString('en-US', options);
    const endDate = eDate.toLocaleString('en-US', options);

    return (
        <div className='grid md:grid-cols-2 gap-6'>
            <div className="relative aspect-square w-full rounded-sm">
                <div className="absolute inset-0">
                    <img
                        src={event?.image || mcTickets}
                        alt={event?.name || 'Event'}
                        className={`w-full h-full object-cover ${isEventUnavailable() ? 'filter grayscale' : ''}`}
                    />
                </div>

                {isEventSoldOut() && !isEventEnded() && (
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="bg-red-600 bg-opacity-70 text-white text-5xl font-bold p-3 -rotate-12 transform origin-center  [text-shadow:_2px_2px_4px_rgb(0_0_0_/_40%),_4px_4px_8px_rgb(0_0_0_/_30%)]">
                            SOLD OUT
                        </div>
                    </div>
                )}

                {isEventEnded() && (
                    <div className="absolute inset-0 flex items-center justify-center">
                        <div className="bg-green-600 bg-opacity-55 text-white text-5xl font-bold p-3 -rotate-12 transform origin-center [text-shadow:_2px_2px_4px_rgb(0_0_0_/_40%),_4px_4px_8px_rgb(0_0_0_/_30%)]">
                            COMPLETED
                        </div>
                    </div>
                )}
            </div>

            <div className='space-y-2'>
                <h1 className="text-2xl font-bold">{event?.name ? event.name.charAt(0).toUpperCase() + event.name.slice(1) : ""}</h1>

                <div className='flex items-center gap-2 text-gray-500'>
                    <PiDiscoBall className='text-xl text-gray-700' />
                    <span >{event?.venue}</span>
                </div>

                <div className='flex items-start gap-2 text-gray-500'>
                    <MapPinned className='w-4 h-4 text-gray-700' />
                    <span>
                        {event?.address?.rue}, {event?.address?.ville},{' '}
                        {event?.address?.region}, {event?.address?.codePostale},{' '}
                        {event?.address?.pays}
                    </span>
                </div>

                <div className='flex items-center gap-2 text-gray-500'>
                    <CalendarIcon className='w-4 h-4 text-gray-700' />
                    <span>Start: {startDate}</span>
                </div>
                <div className='flex items-center gap-2 text-gray-500'>
                    <CalendarIcon className='w-4 h-4 text-gray-700' />
                    <span>End: {endDate}</span>
                </div>

                <div className='flex items-center gap-2 text-gray-500'>
                    <UserIcon className='w-4 h-4 text-gray-700' />
                    <span>Promoter: {event?.promoter}</span>
                </div>
                <div className='flex items-center gap-2 text-gray-500'>
                    <PhoneIcon className='w-4 h-4 text-gray-700' />
                    <span>Promoter Phone: {event?.codePhone}{event?.promoterPhone}</span>
                </div>
            </div>
        </div>
    )
}

const TicketSelection = ({ ticketTypeRef, ticketCountRef, event, errors }) => {
    const isEventEnded = () => {
        if (!event?.dateEnd) return false;
        const eventEndDate = new Date(event.dateEnd);
        const currentDate = new Date();
        const isEnded = eventEndDate < currentDate;
        return isEnded;
    };

    const isEventSoldOut = () => {
        const isSoldOut = event?.ticketsType?.length === 0;
        return isSoldOut;
    };

    const isEventUnavailable = () => {
        const unavailable = isEventEnded() || isEventSoldOut();
        return unavailable;
    };

    return (
        <div className="space-y-4">
            <div className="flex gap-4">
                <div className="flex-1 space-y-2">
                    <label
                        htmlFor="ticketType"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Select Ticket Type
                        <span className="text-red-500 ml-1">*</span>
                    </label>
                    <div className="relative">
                        <select
                            id="ticketType"
                            className={`block w-full pl-3 pr-10 py-2 text-base border border-gray-300 bg-white h-10 sm:text-sm rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors ${errors.ticketType ? 'border-red-500' : ''
                                }`}
                            onChange={(e) => {
                                ticketTypeRef.current = e.target.value;
                            }}
                            defaultValue=""
                            required
                            disabled={isEventUnavailable()}
                            aria-required="true"
                        >
                            <option value="" disabled>
                                Select ticket type
                            </option>
                            {event?.ticketsType?.map((evt) => (
                                <option key={evt._id} value={evt._id}>
                                    {evt?.name} - {evt?.price}
                                </option>
                            ))}
                        </select>

                        {errors.ticketType && (
                            <p className="text-red-500 text-xs mt-1">{errors.ticketType}</p>
                        )}
                    </div>
                </div>

                <div className="w-32 space-y-2">
                    <label
                        htmlFor="ticketCount"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Quantity
                        <span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                        id="ticketCount"
                        type="number"
                        min="1"
                        max="10"
                        defaultValue="1"
                        ref={ticketCountRef}
                        className={`block w-full border border-gray-300 rounded-sm shadow-sm py-2 px-3 h-10 sm:text-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors ${errors.ticketCount ? 'border-red-500' : ''
                            }`}
                        required
                        disabled={isEventUnavailable()}
                        aria-required="true"
                    />
                    {errors.ticketCount && (
                        <p className="text-red-500 text-xs mt-1">{errors.ticketCount}</p>
                    )}
                </div>
            </div>
        </div>
    );
};

const UserForm = ({ event, nameRef, emailRef, bCodePhoneRef, phoneRef, errors }) => {
    const isEventEnded = () => {
        if (!event?.dateEnd) return false
        const eventEndDate = new Date(event.dateEnd)
        const currentDate = new Date()
        const isEnded = eventEndDate < currentDate
        return isEnded
    }

    const isEventSoldOut = () => {
        const isSoldOut = event?.ticketsType?.length === 0
        return isSoldOut
    }

    const isEventUnavailable = () => {
        const unavailable = isEventEnded() || isEventSoldOut()
        return unavailable
    }


    // Convert countries to react-select options format
    const countryOptions = useMemo(() => {
        return countries.map(country => ({
            value: country.dial_code,
            label: `${country.emoji} ${country.dial_code}`,
        }));
    }, [countries]);

    // Wrap Select in forwardRef to work with ref
    const CountrySelect = forwardRef(({ onChange, ...props }, ref) => {
        const handleChange = (selectedOption) => {
            // Update the ref value manually
            if (ref && 'current' in ref) {
                ref.current = selectedOption ? selectedOption.value : '';
            }
            // Call onChange if provided
            if (onChange) {
                onChange(selectedOption ? selectedOption.value : '');
            }
        };

        return (
            <Select
                {...props}
                options={countryOptions}
                placeholder="Select"
                onChange={handleChange}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        width: '9rem',
                        height: "42px",
                        borderRadius: '2px',
                        boxShadow: 'none',
                        borderColor:'#E0E3E7',
                        '&:focus-within': {
                            borderColor: '#D73556',
                            boxShadow: state.isFocused ? '0 0 0 1px #D73556' : 'none',
                        }
                    }),
                }}
            />
        );
    });

    return (

        <>
            <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex-1">
                    <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Full Name
                        <span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                        id="name"
                        type="text"
                        placeholder="John Doe"
                        required
                        ref={nameRef}
                        className={`mt-1 w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors ${errors.name ? "border-red-500" : ""
                            }`}
                        disabled={isEventUnavailable()}
                    />
                    {errors.name && (
                        <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                    )}
                </div>
                <div className="flex-1">
                    <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Email
                        <span className="text-red-500 ml-1">*</span>
                    </label>
                    <input
                        id="email"
                        type="email"
                        placeholder="john@example.com"
                        required
                        ref={emailRef}
                        className={`mt-1 w-full px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors${errors.email ? "border-red-500" : ""
                            }`}
                        disabled={isEventUnavailable()}
                    />
                    {errors.email && (
                        <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                    )}
                </div>
            </div>

            <div>
                

                    <div className=' rounded-xl transition-all duration-300 '>

                        <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                        >
                            Phone Number
                            <span className="text-red-500 ml-1">*</span>
                        </label>

                        <div className="flex mt-1">
                            <CountrySelect
                                ref={bCodePhoneRef}
                                disabled={isEventUnavailable()}
                            />

                            <input
                                type="tel"
                                placeholder="phone number"
                                ref={phoneRef}
                                className="w-full ms-1 px-3 py-2 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-primaryColor transition-colors"
                                disabled={isEventUnavailable()}
                            />


                        </div>
                        {errors.bCodePhone && (
                            <p className="text-red-500 text-xs mt-1">{errors.bCodePhone}</p>
                        )}
                        {errors.phone && (
                            <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
                        )}
                    </div>


               
            </div>





        </>

    )
}

export default function GeneratePublicLink() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { event, loading, statusCode, error } = useSelector((state) => state.events)
    const { id } = useParams()


    const ticketTypeRef = useRef('')
    const ticketCountRef = useRef(null)
    const nameRef = useRef(null)
    const emailRef = useRef(null)
    const phoneRef = useRef(null)
    const bCodePhoneRef = useRef(null)
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [errors, setErrors] = useState({})

    useEffect(() => {
        dispatch(getEventById(id))
    }, [])


    const validateForm = () => {
        let formErrors = {}
        if (!ticketTypeRef.current)
            formErrors.ticketType = 'Please select a ticket type'
        if (
            !ticketCountRef.current?.value ||
            parseInt(ticketCountRef.current.value) < 1
        )
            formErrors.ticketCount = 'Please enter a valid number of tickets'
        if (!nameRef.current?.value.trim()) formErrors.name = 'Name is required'
        if (!emailRef.current?.value.trim()) formErrors.email = 'Email is required'
        if (!phoneRef.current?.value.trim()) formErrors.phone = 'Phone number is required'
        if (!bCodePhoneRef.current?.trim()) formErrors.CodePhone = 'Phone number code is required'
        return formErrors
    }



    const resetForm = () => {
        if (ticketTypeRef.current) ticketTypeRef.current = '';
        if (ticketCountRef.current) ticketCountRef.current.value = '1';
        if (nameRef.current) nameRef.current.value = '';
        if (emailRef.current) emailRef.current.value = '';
        if (phoneRef.current) phoneRef.current.value = '';
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const formErrors = validateForm()
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors)
        } else {
            setErrors({})
            setIsSubmitting(true);
            dispatch(addPublicLink(
                {
                    ticketType: ticketTypeRef.current,
                    nbTicketMax: ticketCountRef.current?.value,
                    buyerName: nameRef.current?.value,
                    buyerEmail: emailRef.current?.value,
                    bCodePhone: bCodePhoneRef.current,
                    numTel: phoneRef.current?.value,
                    event: id
                }
            )).then((response) => {

                setIsSubmitting(false);

                if (response?.payload?._id) {
                    navigate(`/ticket/${response.payload._id}`);
                }

                if (response?.error) {
                    triggerNotification(
                        "Ticket Link Generation Failed !",
                        "We encountered an error while generating your ticket link. Please try again, or contact support if the issue persists.",
                        "danger",
                        15000
                    );
                }
                resetForm();

            }).catch(() => {
                resetForm();
                setIsSubmitting(false);
            });
        }
    }

    const isEventEnded = () => {
        if (!event?.dateEnd) return false
        const eventEndDate = new Date(event.dateEnd)
        const currentDate = new Date()
        const isEnded = eventEndDate < currentDate
        return isEnded
    }

    const isEventSoldOut = () => {
        const isSoldOut = event?.ticketsType?.length === 0
        return isSoldOut
    }

    const isEventUnavailable = () => {
        const unavailable = isEventEnded() || isEventSoldOut()
        return unavailable
    }

    if (loading) {
        return <LoadingSpinner></LoadingSpinner>
    }

    if (error) {
        return <Error statusCode={statusCode} />;
    }

    return (
        <div className='min-h-screen px-4 sm:px-6 lg:px-8 bg-gray-100 flex flex-col items-center justify-center'>
            <img src={mcTickets} alt='time out' width={250} />
            <div className='max-w-3xl mx-auto sm:my-2 mb-8 bg-white shadow rounded-sm overflow-hidden'>
                <div className='flex flex-col'>
                    <div className='px-4 py-5 sm:p-6'>
                        <EventDetails event={event} isUnavailable={isEventUnavailable()} />
                        <form onSubmit={handleSubmit} className='mt-8 space-y-6'>
                            <TicketSelection
                                ticketTypeRef={ticketTypeRef}
                                ticketCountRef={ticketCountRef}
                                event={event}
                                errors={errors}
                            />
                            <UserForm
                                event={event}
                                nameRef={nameRef}
                                emailRef={emailRef}
                                bCodePhoneRef={bCodePhoneRef}
                                phoneRef={phoneRef}
                                errors={errors}
                            />
                            <div className='w-full sm:w-auto flex justify-end mt-6 sm:mt-8'>
                                <button
                                    type='submit'
                                    className={`w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-sm shadow-sm text-base font-bold text-white ${isEventUnavailable() ? 'bg-gray-400' : 'bg-primaryColor hover:bg-primaryColor'} focus:outline-none`}
                                    disabled={isEventUnavailable() || isSubmitting}
                                >
                                    {isSubmitting ? "...Loading" : <><CheckCheck className='me-2' /> Confirm and continue</>}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <AppStorePopup
                content='Discover more events by downloading our app for a better experience on your mobile device.'
            />
        </div>
    )
}
