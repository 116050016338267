import { useState } from 'react'
import { CalendarDays, MapPin, Phone, Users, Ticket, Link, Check, Copy, X } from "lucide-react"


const EventDetails = ({ isOpen, toggleDetailsModal, event }) => {
    const [copied, setCopied] = useState(false)

    const copyToClipboard = () => {
        const url = process.env.NODE_ENV === 'production'
            ? `${process.env.REACT_APP_PROD_URL}/event/${event._id}`
            : `${window.location.origin}/event/${event._id}`;

        navigator.clipboard.writeText(url);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    }

    if (!isOpen) {
        return null
    }

    return (

        <div className="p-4">
            {
                isOpen && (
                    <div className="fixed -inset-0 z-50 bg-gray-600 bg-opacity-60 flex justify-center items-center p-4">
                        <div className="bg-white rounded-sm shadow-xl max-w-md w-full max-h-[90vh] overflow-y-auto relative">
                            <div className="bg-gradient-to-br from-[#d73757] to-[#eb7d87] rounded-sm p-6 text-white shadow-lg">
                                <button
                                    onClick={toggleDetailsModal}
                                    className="absolute top-4 right-4 text-white hover:text-gray-200 focus:outline-none"
                                    aria-label="Close card"
                                >
                                    <X className="h-6 w-6" />
                                </button>
                                <h2 className="text-3xl font-extrabold mb-2">{event?.name}</h2>
                                <div className="flex flex-col space-y-1 text-gray-100">
                                    <div className="flex items-center space-x-2">
                                        <MapPin className="h-5 w-5" />
                                        <span>{event?.venue}</span>
                                    </div>
                                    <div className="pl-7 text-sm">
                                        {event?.address?.rue}, {event?.address?.ville}, {event?.address?.region}, {event?.address?.pays}
                                    </div>
                                </div>
                            </div>

                            <div className="bg-white rounded-sm shadow-lg p-6 space-y-6">
                                <div className="flex flex-col space-y-4">
                                    <div className="flex items-start space-x-4">
                                        <CalendarDays className="h-6 w-6 text-primaryColorLight" />
                                        <div>
                                            <p className="font-semibold text-gray-700">Start:</p>
                                            <p className="text-gray-600">
                                                {`${new Date(event?.dateStart).getFullYear()}-${String(new Date(event?.dateStart).getMonth() + 1).padStart(2, '0')}-${String(new Date(event?.dateStart).getDate()).padStart(2, '0')} ${String(new Date(event?.dateStart).getHours()).padStart(2, '0')}:${String(new Date(event?.dateStart).getMinutes()).padStart(2, '0')}`}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-start space-x-4">
                                        <CalendarDays className="h-6 w-6 text-primaryColorLight" />
                                        <div>
                                            <p className="font-semibold text-gray-700">End:</p>
                                            <p className="text-gray-600">
                                                {`${new Date(event?.dateEnd).getFullYear()}-${String(new Date(event?.dateEnd).getMonth() + 1).padStart(2, '0')}-${String(new Date(event?.dateEnd).getDate()).padStart(2, '0')} ${String(new Date(event?.dateEnd).getHours()).padStart(2, '0')}:${String(new Date(event?.dateEnd).getMinutes()).padStart(2, '0')}`}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-start space-x-4">
                                    <Users className="h-6 w-6 text-primaryColorLight" />
                                    <div>
                                        <p className="font-semibold text-gray-700">{event?.promoter}</p>
                                        <div className="flex items-center text-gray-500">
                                            <Phone className="h-4 w-4 mr-2" />
                                            <span>{event?.promoterPhone}</span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <p className="font-semibold text-gray-700 mb-2">Team Affected:</p>
                                    {event?.team ? (
                                        <span
                                            className="text-white px-3 py-1 rounded-full text-md font-medium"
                                            style={{ backgroundColor: event?.team?.teamColor }}
                                        >
                                            {event?.team?.teamName}
                                        </span>
                                    ) : (
                                        <span className="text-gray-500 italic">No team affected</span>
                                    )}
                                </div>



                                <div>
                                    <p className="font-semibold text-gray-700 mb-2">Ticket Types:</p>
                                    {event.ticketsType && event.ticketsType.length > 0 ? (
                                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                                            {event?.ticketsType?.map((ticket, index) => (
                                                <div key={index} className="flex items-center justify-between bg-gray-100 p-3 rounded-lg transition-transform hover:scale-105">
                                                    <Ticket className="h-5 w-5 text-indigo-600 mr-2" />
                                                    <span className="text-gray-700">{ticket?.name}</span>
                                                    <span className="font-bold text-indigo-600">{ticket?.price}</span>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="bg-red-100 text-red-800 px-4 py-2 rounded-lg font-semibold">
                                            Sold Out
                                        </div>
                                    )}
                                </div>

                                <div className="mt-6">
                                    <p className="font-semibold text-gray-700 mb-2">Event Access:</p>
                                    {event?.publicUrl ? (
                                        <div className="flex items-center space-x-2">
                                            <input
                                                type="text"
                                                value={`${process.env.NODE_ENV === 'production'
                                                    ? `${process.env.REACT_APP_PROD_URL}/event/${event?._id}`
                                                    : `${window.location.origin}/event/${event?._id}`
                                                    }`}
                                                readOnly
                                                className="flex-grow px-3 py-2 border border-gray-300 rounded-l-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                            />
                                            <button
                                                onClick={copyToClipboard}
                                                className="bg-primaryColorLight text-white px-4 py-3 rounded-r-lg hover:bg-primaryColor focus:outline-none focus:ring-2 focus:bg-green-500 focus:ring-green-500 focus:ring-offset-2 transition-colors duration-200"
                                            >
                                                {copied ? <Check className="h-5 w-5" /> : <Copy className="h-5 w-5" />}
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="bg-gray-100 text-gray-800 px-4 py-2 rounded-lg font-semibold">
                                            Private Event
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>

    )
}

export default EventDetails