import React from 'react';

const BuyerInfo = ({ link }) => (
  // <div className='my-8'>
  //   <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
  //     <div>
  //       <label className='block text-sm font-medium text-gray-700'>
  //         Buyer Name
  //       </label>
  //       <input
  //         type='text'
  //         value={link.buyerName}
  //         disabled
  //         className='mt-1 block w-full px-3 py-2 h-10 bg-white border border-gray-300 rounded-md shadow-sm'
  //       />
  //     </div>
  //     <div>
  //       <label className='block text-sm font-medium text-gray-700'>
  //         Buyer Email
  //       </label>
  //       <input
  //         type='email'
  //         value={link.buyerEmail}
  //         disabled
  //         className='mt-1 block w-full px-3 py-2 h-10 bg-white border border-gray-300 rounded-md shadow-sm'
  //       />
  //     </div>
  //     <div>
  //       <label className='block text-sm font-medium text-gray-700'>
  //         Buyer PhoneNumber
  //       </label>
  //       <input
  //         type='text'
  //         value={link.numTel}
  //         disabled
  //         className='mt-1 block w-full px-3 py-2 h-10 bg-white border border-gray-300 rounded-md shadow-sm'
  //       />
  //     </div>
  //   </div>
  // </div>
  <div className="my-8">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">Buyer Name</label>
        <input
          type="text"
          value={link.buyerName}
          disabled
          className="mt-1 block w-full px-3 py-2 h-10 bg-gray-100  border border-gray-300 rounded-sm shadow-sm"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Buyer Email</label>
        <input
          type="email"
          value={link.buyerEmail}
          disabled
          className="mt-1 block w-full px-3 py-2 h-10 bg-gray-100  border border-gray-300 rounded-sm shadow-sm"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Buyer Phone Number</label>
        <input
          type="text"
          value={link.numTel}
          disabled
          className="mt-1 block w-full px-3 py-2 h-10 bg-gray-100  border border-gray-300 rounded-sm shadow-sm"
        />
      </div>
    </div>
  </div>
);

export default BuyerInfo;