import React, { useEffect, useState } from 'react'
import DataTable from '../components/Layout/DataTable/DataTable'
import { useDispatch, useSelector } from 'react-redux'
import { deleteTicketById, getTickets } from '../services/Redux/slices/tickets'
import EditTicketStatus from '../components/Core/Modals/Edit/EditTicketStatus'
import { format, parseISO } from 'date-fns'
import LoadingSpinner from '../components/Layout/LoadingSpinner'
import Pagination from '../components/Core/Pagination/Pagination'
import { getEvents } from '../services/Redux/slices/EventsSlice'
import CountUp from "react-countup";
import PDFGenerator from '../components/Core/PDF/PDFGenerator'
import { getMyTeamMembers } from '../services/Redux/slices/TeamSlice'
import { useParams } from 'react-router-dom'

function Tickets() {
  const { tickets, loading, currentPage, totalPages, totalTickets, ticketsWithoutPagination } = useSelector((state) => state.tickets)
  const { events } = useSelector((state) => state.events) || []
  const { team } = useSelector((state) => state.teams) || []
  const { user } = useSelector((state) => state.auth)

  const dispatch = useDispatch()


  const params = useParams();

  const [actualPage, setActualPage] = useState(1)
  const [buyerName, setBuyerName] = useState();
  const [participant, setParticipant] = useState();
  const [participantPhone, setParticipantPhone] = useState();
  const [eventId, setEventId] = useState(params?.id);
  const [filterCreatedBy, setFilterCreatedBy] = useState()

  useEffect(() => {
    dispatch(getEvents({ eventName: null, eventDate: null }))
    dispatch(getMyTeamMembers({ teamName: null }))
  }, [dispatch])



  useEffect(() => {
    dispatch(getTickets({ page: actualPage, limit: 10, buyerName, participant, participantPhone, eventId, filterCreatedBy }))
  }, [dispatch, actualPage, buyerName, participant, participantPhone, eventId, filterCreatedBy])

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setActualPage(page);
    }
  };

  const columns = [
    { label: 'buyer', key: 'buyer' },
    { label: 'participant', key: 'participant' },
    { label: 'participant Phone', key: 'participantPhone' },
    {
      label: 'status',
      key: 'status',
      render: (row) => {
        const statusColor = row.status === 'valid' ? 'green' : row.status === 'entered' ? 'red' : 'black';
        const formattedStatus = row.status === 'valid' ? 'Valid' : row.status === 'entered' ? 'Entered' : row.status;
        return (
          <span style={{ color: statusColor }} className="py-2 font-bold">
            {formattedStatus}
          </span>
        );
      }
    },
    { label: 'event', key: 'event.name' },
    {
      label: 'event date',
      key: 'event.dateStart',
      render: (row) => {
        if (!row.event?.dateStart) {
          return 'No start date'
        }
        const date = parseISO(row?.event?.dateStart)
        return isNaN(date) ? 'Invalid date' : format(date, 'dd/MM/yyyy HH:mm')
      }
    },
    {
      label: 'Scanned By',
      key: 'scannedBy',
      render: (row) => {
        return (
          <div className='py-1'>
            {row.scannedBy
              ? `${row.scannedBy.profile?.firstName || ''} ${row.scannedBy.profile?.lastName || ''}`
              : "-"}
          </div>
        );
      }
    },
    {
      label: 'ticket Type',
      key: 'type',
      render: (row) => {
        return (
          <div className='py-1'>
            <span> {row?.type?.name} - {row?.type?.price}TND </span>
          </div>
        );
      }
    },
    {
      label: 'Seller',
      key: 'link',
      render: (row) => {
        return (
          <div className='py-1'>
            {
              row?.link?.createdBy ? <span> {row?.link?.createdBy?.profile?.firstName} {row?.link?.createdBy?.profile?.lastName}  </span> : <span className="text-red-400 font-bold">Public</span>
            }
          </div>
        );
      }
    },
    { label: 'ticket pdf', key: 'pdf' }
  ]

  const toEditColumns = [
    { label: 'Status', key: 'status', type: 'text', required: true }
  ]

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedTicket, setSelectedTicket] = useState(null)

  const handleEdit = (updated) => {
    setIsEditModalOpen(false) // Close the edit modal after editing
  }

  const handleEditClick = (ticket) => {
    setSelectedTicket(ticket)
    setIsEditModalOpen(true)
  }


  const uniqueSellers = new Map();

  team.flatMap(teamMember => teamMember.sellers).forEach(seller => {
    if (!uniqueSellers.has(seller._id)) {
      uniqueSellers.set(seller._id, {
        _id: seller._id,
        name: `${seller.profile.firstName} ${seller.profile.lastName} - [${seller.slug}]`
      });
    }
  });
  const sellersArray = Array.from(uniqueSellers.values());
  return (
    <div className='container mx-auto'>
      <div className=' flex items-center justify-between mb-4'>
        <p className='text-2xl font-medium '>Tickets</p>
        <div>
          <PDFGenerator tickets={ticketsWithoutPagination} />
        </div>
      </div>

      {/* <div className='lg:flex lg:flex-row items-start lg:items-center lg:justify-between  border-b  border-primaryColorLight pb-1 mb-4'>
        <div className='flex flex-col 2xl:flex-row gap-4'>

          <div className='flex flex-col w-full md:w-52'>
            <label className='text-gray-500 text-sm'> Filter By <span className='text-primaryColorLight'> Buyer </span>  </label>
            <input
              type='text'
              placeholder='Buyer Name' // Input field for buyer name filter
              className='p-2 border rounded-sm mb-1 w-full md:w-52 h-9'
              onChange={(e) => setBuyerName(e.target.value)}
            />
          </div>

          <div className='flex flex-col w-full md:w-52'>
            <label className='text-gray-500 text-sm'> Filter By <span className='text-primaryColorLight'> Participant </span>  </label>
            <input
              type='text'
              placeholder='Participant Name' // Input field for buyer name filter
              className='p-2 border rounded-sm mb-1 w-full md:w-52 h-9'
              onChange={(e) => setParticipant(e.target.value)}
            />
          </div>

          <div className='flex flex-col w-full md:w-52'>
            <label className='text-gray-500 text-sm'> Filter By <span className='text-primaryColorLight'> Participant Phone </span>  </label>
            <input
              type='text'
              placeholder='Participant Phone' // Input field for buyer name filter
              className='p-2 border rounded-sm mb-1 w-full md:w-52 h-9'
              onChange={(e) => setParticipantPhone(e.target.value)}
            />
          </div>


          <div className='flex flex-col w-full md:w-60'>
            <label className='text-gray-500 text-sm'> Filter by <span className='text-primaryColorLight'> Event </span> </label>
            <select
              className='p-2 border rounded-sm mb-1 w-full md:w-60 h-9'
              onChange={(e) => setEventId(e.target.value)}
              defaultValue={params?.id}
            >
              <option value={undefined}>
                All
              </option>
              {events?.map((option) => (
                <option key={option._id} value={option?._id}>
                  {option?.name}
                </option>
              ))}
            </select>
          </div>

          <div className='flex flex-col w-full md:w-60'>
            <label className='text-gray-500 text-sm'>
              {' '}
              Filter by{' '}
              <span className='text-primaryColorLight'> Seller </span>{' '}
            </label>
            <select
              className='p-2 border rounded-sm mb-1 w-full md:w-60 h-9'
              onChange={(e) => setFilterCreatedBy(e.target.value)}
            >
              <option value={undefined}>All</option>
              <option value={user?._id}>{user?.name}</option>
              {sellersArray.map((option) => (
                <option key={option?.value} value={option?._id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>



        </div>

        <p className='text-m text-gray-500 mt-2 md:mt-0'>
          Total tickets :
          <span className='text-lg font-medium text-primaryColor'>
            <CountUp end={totalTickets} />
          </span>{' '}
        </p>
      </div> */}
      <div className="border-b border-primaryColorLight pb-4 mb-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-4">
          {/* Buyer Filter */}
          <div className="flex flex-col">
            <label className="text-gray-500 text-sm">
              Filter By <span className="text-primaryColorLight">Buyer</span>
            </label>
            <input
              type="text"
              placeholder="Buyer Name"
              className="p-2 border rounded-sm h-9 w-full"
              onChange={(e) => setBuyerName(e.target.value)}
            />
          </div>

          {/* Participant Filter */}
          <div className="flex flex-col">
            <label className="text-gray-500 text-sm">
              Filter By <span className="text-primaryColorLight">Participant</span>
            </label>
            <input
              type="text"
              placeholder="Participant Name"
              className="p-2 border rounded-sm h-9 w-full"
              onChange={(e) => setParticipant(e.target.value)}
            />
          </div>

          {/* Participant Phone Filter */}
          <div className="flex flex-col">
            <label className="text-gray-500 text-sm">
              Filter By <span className="text-primaryColorLight">Participant Phone</span>
            </label>
            <input
              type="text"
              placeholder="Participant Phone"
              className="p-2 border rounded-sm h-9 w-full"
              onChange={(e) => setParticipantPhone(e.target.value)}
            />
          </div>

          {/* Event Filter */}
          <div className="flex flex-col">
            <label className="text-gray-500 text-sm">
              Filter by <span className="text-primaryColorLight">Event</span>
            </label>
            <select
              className="p-2 border rounded-sm h-9 w-full"
              onChange={(e) => setEventId(e.target.value)}
              defaultValue={params?.id}
            >
              <option value={undefined}>All</option>
              {events?.map((option) => (
                <option key={option._id} value={option?._id}>
                  {option?.name}
                </option>
              ))}
            </select>
          </div>

          {/* Seller Filter */}
          <div className="flex flex-col">
            <label className="text-gray-500 text-sm">
              Filter by <span className="text-primaryColorLight">Seller</span>
            </label>
            <select
              className="p-2 border rounded-sm h-9 w-full"
              onChange={(e) => setFilterCreatedBy(e.target.value)}
            >
              <option value={undefined}>All</option>
              <option value={user?._id}>{user?.name}</option>
              {sellersArray.map((option) => (
                <option key={option?.value} value={option?._id}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="text-right">
          <p className="text-m text-gray-500">
            Total tickets:{' '}
            <span className="text-lg font-medium text-primaryColor">
              <CountUp end={totalTickets} />
            </span>
          </p>
        </div>
      </div>

      <div className='my-5'>
        {
          loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <DataTable
                columns={columns}
                data={tickets}
                onEditClick={handleEditClick}
                onDeleteClick={(ticket) => dispatch(deleteTicketById(ticket))}
              />
              {isEditModalOpen && (
                <EditTicketStatus
                  isOpen={isEditModalOpen}
                  onClose={() => setIsEditModalOpen(false)}
                  onEdit={handleEdit}
                  ticket={selectedTicket}
                  columns={toEditColumns}
                />
              )}
            </>
          )
        }
      </div>

      <div className='flex justify-end'>
        {totalPages > 0 &&
          <Pagination
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            totalPages={totalPages}
          />}
      </div>
    </div>
  )
}

export default Tickets
