import React from 'react';
import timeOut from '../assets/images/timeOut.png'
import error from '../assets/images/error.png'
import notFound from '../assets/images/notFound.jpg' 
import mcTickets from '../assets/images/mcp.svg'

const Error = ({ statusCode }) => {


    if (statusCode == 410) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <img src={mcTickets} alt='time out' width={250} />
                <div className="py-8 px-6">
                    <div className="text-center">
                        <div className="mb-6">
                            <img src={timeOut} alt='time out' width={550} />
                        </div>
                        <p className="text-2xl font-semibold">Oops… The link has expired</p>
                        <p className="text-secondary text-gray-600 mt-2">
                            We are sorry but the link you are trying to access is no longer valid
                        </p>

                    </div>
                </div>
            </div>
        );
    }

    if (statusCode == 404) {
        return (
            <div className="flex flex-col items-center justify-center h-screen">
                <img src={mcTickets} alt='time out' width={250} />
                <div className="py-8 px-6">
                    <div className="text-center">
                        <div className="mb-6">
                            <img src={notFound} alt='time out' width={650} />
                        </div>
                        <p className="text-2xl font-semibold">Oops… Page Not Found </p>
                        <p className="text-secondary text-gray-600 mt-2">
                            We are sorry but the link you are trying to access is no longer valid
                        </p>

                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <img src={mcTickets} alt='time out' width={250} />
            <div className="py-8 px-6">
                <div className="text-center">
                    <div className="mb-6">
                        <img src={error} alt='time out' width={550} />
                    </div>
                    <p className="text-2xl font-semibold">Oops… Something went wrong</p>
                    <p className="text-secondary text-gray-600 mt-2">
                        We are sorry, but an error occurred
                    </p>

                </div>
            </div>
        </div>
    );
};

export default Error;