import React, { useEffect, useState } from 'react';
import BuyerInfo from './BuyerInfo';
import ParticipantsList from './ParticipantsList';
import TicketInfo from './TicketInfo';
import mcTickets from '../../../assets/images/mcp.svg'
import {
  CalendarIcon,
  MapPinned,
  PhoneIcon,
  UserIcon
} from 'lucide-react'
import { PiDiscoBall, PiInvoice, PiTicketLight } from "react-icons/pi";
import CountdownTimer from './CountdownTimer';


const GenerateTicket = ({
  link,
  participants,
  handleParticipantChange,
  removeParticipant,
  addParticipant,
  exceededError,
  validationError,
  onSubmit,
  handleSubmit
}) => {

  const SDate = new Date(link?.event?.dateStart);
  const eDate = new Date(link?.event?.dateEnd);

  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  };

  const startDate = SDate.toLocaleString('en-US', options);
  const endDate = eDate.toLocaleString('en-US', options);

  return (
    <>
      <div className='min-h-screen py-8 px-4 sm:px-6 lg:px-8 bg-gray-100 flex flex-col items-center justify-center'>
        <img src={mcTickets} alt='time out' width={250} />
        {
          link?.linkType === "Public" && <CountdownTimer createdAt={link?.createdAt}/>
        }
        
        <div className='max-w-3xl mx-auto my-2 mb-8 bg-white shadow rounded-sm overflow-hidden'>

          <div className='flex flex-col'>

            <div className='px-4 py-5 sm:p-6'>

              {link?.event?.image && (

                <div className='grid md:grid-cols-2 gap-6'>
                  <div className='aspect-square w-full overflow-hidden rounded-sm'>
                    <img
                      className=' w-full h-full object-cover '
                      src={link?.event?.image}
                      alt={link?.event?.name}
                    />
                  </div>

                  <div className='space-y-2'>
                    <h1 className="text-2xl font-bold">
                      {link?.event?.name ? link?.event?.name.charAt(0).toUpperCase() + link?.event?.name.slice(1) : ""}
                    </h1>

                    <div className='flex items-center gap-2 text-gray-500'>
                      <PiDiscoBall className='text-xl text-gray-700' />
                      <span>{link?.event?.venue}</span>
                    </div>


                    <div className='flex items-start gap-2 text-gray-500'>
                      <MapPinned className='w-4 h-4 text-gray-700' />
                      <span>
                        {link?.event?.address?.rue}, {link?.event?.address?.ville},{' '}
                        {link?.event?.address?.region}, {link?.event?.address?.codePostale},{' '}
                        {link?.event?.address?.pays}
                      </span>

                    </div>

                    <div className='flex items-center gap-2 text-gray-500'>
                      <CalendarIcon className='w-4 h-4 text-gray-700' />
                      <span>Start: {startDate}</span>
                    </div>
                    <div className='flex items-center gap-2 text-gray-500'>
                      <CalendarIcon className='w-4 h-4 text-gray-700' />
                      <span>End: {endDate}</span>
                    </div>
                    <div className='flex items-center gap-2 text-gray-500'>
                      <UserIcon className='w-4 h-4 text-gray-700' />
                      <span>Promoter: {link?.event?.promoter} </span>
                    </div>
                    <div className='flex items-center gap-2 text-gray-500'>
                      <PhoneIcon className='w-4 h-4 text-gray-700' />
                      <span>Promoter Phone: {link?.event?.promoterPhone}</span>
                    </div>
                  </div>
                </div>
              )}
              <BuyerInfo link={link} />
              <TicketInfo link={link} />
              <div className="flex p-4 mb-4 text-sm text-black rounded-lg bg-orange-300" role="alert">
                <svg className="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span className="sr-only">Info</span>
                <div>
                  <span className="font-medium">Important Notice:</span>
                  <ul className="mt-1.5 list-disc list-inside">
                    <li>The event organizer reserves the right to deny entry if your information is found to be incorrect or if you are under the influence of alcohol or other substances.</li>
                    <li>The event may be photographed.</li>
                    <li>These measures are in place to ensure safety and a smooth experience for all attendees.</li>
                  </ul>
                </div>
              </div>
              <ParticipantsList
                participants={participants}
                participantsCount={link?.nbTicketMax}
                handleParticipantChange={handleParticipantChange}
                removeParticipant={removeParticipant}
                addParticipant={addParticipant}
                exceededError={exceededError}
                validationError={validationError}
              />
              <div className='w-full sm:w-auto flex justify-end mt-6 sm:mt-8'>
                <button
                  type='submit'
                  onClick={handleSubmit}
                  disabled={onSubmit}
                  className='w-full sm:w-auto flex justify-center py-2 px-4 border border-transparent rounded-sm shadow-sm text-base font-bold text-white bg-primaryColor hover:bg-primaryColor focus:outline-none'
                >
                  {onSubmit
                    ? '...Loading'
                    : link.ticketType?.price === 0
                      ? <> <PiTicketLight className='me-2 text-2xl' />  Get your tickets </>
                      : <> <PiInvoice className='me-2 text-2xl' /> Proceed to Details and Payment</>
                  }
                </button>
              </div>


            </div>

          </div>
        </div>
      </div>

    </>
  )
};

export default GenerateTicket;