import {
  BiHomeAlt,
  BiGroup,
  BiNote,
  BiErrorCircle,
  BiBook,
  BiFile,
  BiCalendarEvent,
  BiCalendar,
  BiUser,
  BiLogOut,
  BiLogOutCircle,
  BiSitemap,
  BiLinkAlt,
  BiCustomize,
  BiMoney
} from 'react-icons/bi'
import { PiTicket } from "react-icons/pi";
import { PiHandshakeThin , PiMoneyWavyLight , PiCurrencyCircleDollarBold } from "react-icons/pi";
import { FiType } from "react-icons/fi";

import { VscSymbolClass } from "react-icons/vsc";
import { TbReportMoney } from "react-icons/tb";


import { RiExchangeDollarLine } from "react-icons/ri";




import { Roles } from '../../../enum'
const navMenuLinksData = [
  {
    icon: BiHomeAlt,
    label: 'Dashboard',
    href: '/dashboard',
    roles: [Roles.USER],
  },
  {
    icon: VscSymbolClass,
    label: 'Ticket Types',
    href: '/dashboard/ticketTypes',
    roles: [Roles.USER],
  },
  {
    icon: PiCurrencyCircleDollarBold,
    label: 'My Event Sales',
    href: '/dashboard/my-event-sales',
    roles: [Roles.USER],
  },
  {
    icon: RiExchangeDollarLine,
    label: 'Collaborative Sales',
    href: '/dashboard/collaborative-sales',
    roles: [Roles.USER],
  },
  
  {
    icon: BiLinkAlt,
    label: 'Links',
    href: '/dashboard/links',
    roles: [Roles.USER],
  },
  {
    icon: PiTicket,
    label: 'Tickets',
    href: '/dashboard/tickets',
    roles: [Roles.USER],
  },
  {
    icon: BiGroup,
    label: 'Team',
    href: '/dashboard/team',
    roles: [Roles.USER],
  },
  {
    icon: BiLogOutCircle,
    label: 'Logout',
    href: '/dashboard/logout',
    roles: [Roles.USER],
  }
]

export default navMenuLinksData
